import { TextUtils } from './text-utils';
import { Base64 } from 'js-base64';
import { catchAndLog } from './catch-and-log';

export class InternalSymmetricCryptoApi {
    private static readonly counter: Uint8Array = crypto.getRandomValues(new Uint8Array(16));

    private static readonly EXPORTED_KEY_FORMAT = "raw";

    public static async encrypt(
        key: CryptoKey,
        inputData: Int8Array | Int16Array | Int32Array | Uint8Array | Uint16Array | Uint32Array | Uint8ClampedArray | Float32Array | Float64Array | DataView | ArrayBuffer
    ): Promise<SymmetricEncryptedBlob> {
        return await catchAndLog("InternalSymmetricCryptoApi.encrypt", async () => {
            const encrypted = await crypto.subtle.encrypt(
                <AesCtrParams>{
                    name: "AES-CTR",
                    counter: InternalSymmetricCryptoApi.counter,
                    length: 64
                },
                key,
                inputData
            );

            return <SymmetricEncryptedBlob>{
                counter: InternalSymmetricCryptoApi.counter,
                encrypted
            };
        });
    }

    public static async decrypt(
        key: CryptoKey,
        encryptedBlob: SymmetricEncryptedBlob
    ): Promise<ArrayBuffer> {
        return await catchAndLog("InternalSymmetricCryptoApi.decrypt", async () => {
            const decrypted = await window.crypto.subtle.decrypt(
                <AesCtrParams>{
                    name: "AES-CTR",
                    counter: encryptedBlob.counter,
                    length: 64
                },
                key,
                encryptedBlob.encrypted
            );

            return decrypted;
        });
    }

    public static async wrapKey(keyToWrap: CryptoKey, publicKey: CryptoKey): Promise<ArrayBuffer> {
        return await catchAndLog("InternalSymmetricCryptoApi.wrapKey", async () => {
            const wrappedKey = await crypto.subtle.wrapKey(
                "raw",
                keyToWrap,
                publicKey,
                <Algorithm>{
                    name: "RSA-OAEP"
                }
            );

            return wrappedKey;
        });
    }

    public static async unwrapKey(keyToUnwrap: ArrayBuffer, privateKey: CryptoKey): Promise<CryptoKey> {
        return await catchAndLog("InternalSymmetricCryptoApi.unwrapKey", async () => {
            const unwrappedKey = await crypto.subtle.unwrapKey(
                "raw",
                keyToUnwrap,
                privateKey,
                <Algorithm>{
                    name: "RSA-OAEP"
                },
                <Algorithm>{
                    name: "AES-CTR"
                },
                false,
                ["decrypt"]
            );

            return unwrappedKey;
        });
    }

    public static async binaryExportKey(nativeKey: CryptoKey): Promise<ArrayBuffer> {
        return await catchAndLog("InternalSymmetricCryptoApi.binaryExportKey", async () => {
            const jsonNativeKey = JSON.stringify(nativeKey);
            console.debug(`Exporting key: ${jsonNativeKey}`);
            const exportedKey = await crypto.subtle.exportKey(InternalSymmetricCryptoApi.EXPORTED_KEY_FORMAT, nativeKey);
            console.debug(`Successfully exported key as binary: ${jsonNativeKey}\n${exportedKey}`);
            return exportedKey;
        });
    }

    public static async exportKey(nativeKey: CryptoKey): Promise<string> {
        return await catchAndLog("InternalSymmetricCryptoApi.exportKey", async () => {
            const exportedKey = await InternalSymmetricCryptoApi.binaryExportKey(nativeKey);
            const utf16key = TextUtils.arrayBuffer2string(exportedKey as ArrayBuffer);
            const base64key = Base64.encode(utf16key);
            console.debug(`Successfully exported key: ${(JSON.stringify(nativeKey))}\n${base64key}`);
            return base64key;
        });
    }

    public static async binaryImportKey(abKey: ArrayBuffer): Promise<CryptoKey> {
        return await catchAndLog("InternalSymmetricCryptoApi.binaryImportKey", async () => {
            const nativeKey = await crypto.subtle.importKey(
                InternalSymmetricCryptoApi.EXPORTED_KEY_FORMAT,
                abKey,
                "AES-CTR",
                false,
                ["decrypt"]
            );
            return nativeKey;
        });
    }

    public static async importKey(base64Key: string): Promise<CryptoKey> {
        return await catchAndLog("InternalSymmetricCryptoApi.importkey", async () => {
            const utf16key = Base64.decode(base64Key) as string;
            const abKey = TextUtils.string2arrayBuffer(utf16key);
            const nativeKey = await InternalSymmetricCryptoApi.binaryImportKey(abKey);
            return nativeKey;
        });
    }

    public static async generateKey(): Promise<CryptoKey> {
        return await catchAndLog("InternalSymmetricCryptoApi.generateKey", async () => {
            const key = await crypto.subtle.generateKey(
                <AesKeyGenParams>{
                    name: "AES-CTR",
                    length: 256
                },
                true,
                ["encrypt", "decrypt"]
            );

            return key;
        });
    }
}

export interface SymmetricEncryptedBlob {
    counter: Uint8Array;
    encrypted: ArrayBuffer;
}
