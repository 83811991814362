import { IHRManager } from '../interfaces/ihr-manager';
import { IDisposable } from '../../misc/interfaces/idisposable';
import { TaskInfo } from '../interfaces/task-info';
import { ResolvablePromise } from '../../core/resolvable-promise';
import { IHardwareInfo } from '../../misc/interfaces/ihardware-info';
import { IO2Cloud } from '../interfaces/io2-cloud';
import { ICodeExecutorFactory } from '../interfaces/icode-executor-factory';
import { IVolunteerFlowFsm } from '../interfaces/iflow-fsm';
import { EmployerFsmFactory } from './fsm/employer-fsm-factory';
import { VolunteerFsmFactory } from './fsm/volunteer-fsm-factory';
import { CancellationToken } from '../../core/cancellation-token';
import { ICryptography } from '../interfaces/icryptography';
import { DelegatingDisposable } from './delegating-disposable';
import { IChannelService } from '../interfaces/ichannel-service';
import { IComputationTimeRepository } from '../interfaces/Icomputation-time-repository';
import { AuthenticationService } from 'src/app/services';

export class HRManager implements IHRManager {
    public static readonly DEFAULT_CHANNEL_TIME_TO_LIVE_MS = 60 * 60 * 1000; // 1 hr
    public static readonly TASK_EXECUTION_TIMEOUT_MS = 30 * 1000;

    private readonly shutdownTokenSource = CancellationToken.create();
    private readonly volunteerFsms = new Map<string, Map<string, IVolunteerFlowFsm>>();

    private elapsedMs_: number = 0;

    public get elapsedMs(): number {
        return this.elapsedMs_;
    }

    public async dispose(): Promise<void> {
        debugger;
        this.shutdownTokenSource.cancel();
    }

    public get usedCores(): number {
        let result = 0;
        this.volunteerFsms.forEach((fsms) => {
            fsms.forEach((fsm) => {
                if (fsm.correlationId) {
                    result++;
                }
            });
        });
        return result;
    }


    public get nodeId(): string {
        return this.cloud.nodeId;
    }

    public constructor(
        private readonly cloud: IO2Cloud,
        private readonly cryptographyService: ICryptography,
        private readonly employerFsmFactory: EmployerFsmFactory,
        private readonly volunteerFsmFactory: VolunteerFsmFactory,
        private readonly hardwareInfo: IHardwareInfo,
        private readonly codeExecutorFactory: ICodeExecutorFactory,
        private readonly channelService: IChannelService,
        private readonly computationTimeRepository: IComputationTimeRepository,
        private readonly authenticationService: AuthenticationService
    ) {
        console.log(`HR | Created and set messaging hooks`);
    }

    public async startVolunteers(channelIds: any[]): Promise<IDisposable> {
        const stopMonitoringJobPortalTokenSource = CancellationToken.create();
        const volunteers: IVolunteerFlowFsm[] = [];
        const correlationId2volunteerMap = new Map<string, IVolunteerFlowFsm>();
        
        const volunteersCount = this.hardwareInfo.numberOfCpuCores;
        (this.channelService.myVolunteerPeer as any).setMaxListeners(volunteersCount + 5);
        
        for (let i = 0; i < volunteersCount; ++i) {
            const volunteer = await this.volunteerFsmFactory.start(
                this.cloud,
                this,
                correlationId2volunteerMap,
                this.cryptographyService,
                CancellationToken.race(
                    stopMonitoringJobPortalTokenSource.token,
                    this.shutdownTokenSource.token
                ),
                this.codeExecutorFactory,
                this.channelService,
                this.computationTimeRepository,
                this.authenticationService
            );
            volunteers.push(volunteer);
        }

        //this.objectHasher.hashArr(channelIds, channel);
        const registerChannels = () => {
            channelIds.forEach(async (channelId) => {
                await this.channelService.publishMe(channelId);
            });
        };

        const timer = setInterval(registerChannels, 15000);
        registerChannels();

        return new DelegatingDisposable(() => {
            clearInterval(timer);
            stopMonitoringJobPortalTokenSource.cancel();
            volunteers.forEach(async volunteer => await volunteer.cleanup({}));
        });
    }

    public async executeTask<R = void>(
        task: TaskInfo,
        channelIds: any[],
    ): Promise<R> {
        const resultsFuture = new ResolvablePromise<R>();

        const fsm = await this.employerFsmFactory.start(
            this,
            this.cryptographyService,
            channelIds,
            task,
            resultsFuture,
            this.shutdownTokenSource.token,
            this.channelService,
            this.computationTimeRepository,
            this.authenticationService
        );
        if (!fsm) {
            throw new Error(`Cannot start FSM`);
        }
        try {
            const results = await resultsFuture.future;
            return results;
        }
        finally {
            this.elapsedMs_ += (fsm.elapsedMs | 0 || 0);
        }
    }
}
