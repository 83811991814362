import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

@Component({
    selector: 'main-page',
    templateUrl: './main-page.component.html',
    styleUrls: ['./main-page.component.scss'],
    host: { 'class': 'page-component' },
})
export class MainPageComponent implements OnInit {
    constructor(
        private router: Router
    ) { }

    ngOnInit() {
        //this.connection.connect('main-room');
    }

    public employer() {
        console.log('Employer');
        this.router.navigate(['/employer']);
    }

    public volunteer() {
        console.log('Volunteer');
        this.router.navigate(['/volunteer']);
    }

    public mandelbrot() {
        console.log('mandelbrot');
        this.router.navigate(['/mandelbrot']);
    }
}
