import { IDisposable, IDisposableAsync } from '../../misc/interfaces/idisposable';

export class DelegatingDisposable implements IDisposable {
    public constructor(private onDispose: () => void) {
    }

    public dispose(): void {
        const onDispose = this.onDispose;
        this.onDispose = null;

        if (onDispose) {
            onDispose();
        }
    }
}

export class DelegatingDisposableAsync implements IDisposableAsync {
    public constructor(private onDispose: () => Promise<void>) {
    }

    public async dispose(): Promise<void> {
        const onDispose = this.onDispose;
        this.onDispose = null;

        if (onDispose) {
            await onDispose();
        }
    }
}
