import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { DatePipe } from '@angular/common'

import { AppRoutingModule } from "./app-routing.module";
import { MaterialModule } from "./material.module";

import { MonacoEditorModule } from "ngx-monaco-editor";
import { FileUploadModule } from 'ng2-file-upload';
import { ToastrModule } from 'ngx-toastr';

import { GunDb } from './services/gun/gun.service';

// components
import { AppComponent } from "./components/app/app.component";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { MainPageComponent } from "./components/main-page/main-page.component";
import { DemosPageComponent } from "./components/demos-page/demos-page.component";
import { TryPageComponent } from "./components/try-page/try-page.component";
import { TeamPageComponent } from "./components/team-page/team-page.component";
import { EmployerPageComponent } from "./components/employer-page/employer-page.component";
import { VolunteerPageComponent } from "./components/volunteer-page/volunteer-page.component";
import { DemoPrimeNumbersComponent } from "./components/demo-prime-numbers/demo-prime-numbers.component";
import { DemoMandelbrotComponent } from "./components/demo-mandelbrot/demo-mandelbrot.component";
import { DemoVideoChatComponent } from './components/demo-video-chat/demo-video-chat.component';
import { DemoDecentralizedDbComponent } from './components/demo-decentralized-db/demo-decentralized-db.component';
import { DemoAiVideoComponent } from './components/demo-ai-video/demo-ai-video.component';
import { MandelbrotSetComponent } from './components/mandelbrot-set/mandelbrot-set.component';
import { WebTorrentPageComponent } from './components/demo-web-torrent/demo-web-torrent.component';

// pipes
import { CapitalizeFirstLetterPipe } from './pipes/capitalize-first-letter';

// flow
import { WebRTCConfiguration } from "./web-rtcconfiguration";
import { O2Cloud } from "./components/p2p-cloud/flow/impls/o2-cloud";
import { IO2Cloud } from "./components/p2p-cloud/flow/interfaces/io2-cloud";
import { HardwareInfo } from "./components/p2p-cloud/misc/impls/hardware-info";
import { JobMarketInfo } from "./components/p2p-cloud/flow/impls/job-market-info";
import { IRuntime } from "./components/p2p-cloud/flow/interfaces/iruntime";
import { RuntimeService } from "./components/p2p-cloud/core/runtime.service";
import { ICryptography } from "./components/p2p-cloud/flow/interfaces/icryptography";
import { IExecutionContext } from "./components/p2p-cloud/flow/interfaces/iexecution-context";
import { ISerializer } from "./components/p2p-cloud/flow/interfaces/iserializer";
import { ExecutionContext } from "./components/p2p-cloud/core/execution-context";
import { SerializerService } from "./components/p2p-cloud/core/serializer.service";
import { CodeExecutorFactory } from './components/p2p-cloud/flow/impls/executors/code-executor-factory';
import { EmployerFsmFactory } from './components/p2p-cloud/flow/impls/fsm/employer-fsm-factory';
import { VolunteerFsmFactory } from './components/p2p-cloud/flow/impls/fsm/volunteer-fsm-factory';
import { IChannelService } from './components/p2p-cloud/flow/interfaces/ichannel-service';
import { ChannelService } from './components/p2p-cloud/flow/impls/channel-service';
import { ICodeExecutorFactory } from './components/p2p-cloud/flow/interfaces/icode-executor-factory';
import { IJobMarketInfo } from './components/p2p-cloud/flow/interfaces/ijob-market-info';
import { IHardwareInfo } from './components/p2p-cloud/misc/interfaces/ihardware-info';
import { IComputationTimeRepository } from './components/p2p-cloud/flow/interfaces/Icomputation-time-repository';
import { ComputationTimeRepository } from './components/p2p-cloud/flow/impls/computation-time-repository';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { RegisterPageComponent } from './components/register-page/register-page.component';
import { RealCryptographyService } from './components/p2p-cloud/core/real-cryptography.service';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        MainPageComponent,
        DemosPageComponent,
        TryPageComponent,
        TeamPageComponent,
        EmployerPageComponent,
        VolunteerPageComponent,
        DemoPrimeNumbersComponent,
        DemoMandelbrotComponent,
        DemoVideoChatComponent,
        DemoDecentralizedDbComponent,
        DemoAiVideoComponent,
        MandelbrotSetComponent,
        WebTorrentPageComponent,
        LoginPageComponent,
        RegisterPageComponent,
        CapitalizeFirstLetterPipe,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MaterialModule,
        FormsModule,
        FlexLayoutModule,
        MonacoEditorModule.forRoot(), // use forRoot() in main app module only.
        ReactiveFormsModule,
        FileUploadModule,
        ToastrModule.forRoot(),
    ],
    providers: [
        DatePipe,
        { provide: IO2Cloud, useClass: O2Cloud },
        WebRTCConfiguration,
        VolunteerFsmFactory,
        EmployerFsmFactory,
        { provide: ICodeExecutorFactory, useClass: CodeExecutorFactory },
        { provide: IJobMarketInfo, useClass: JobMarketInfo },
        { provide: IHardwareInfo, useClass: HardwareInfo },
        { provide: IRuntime, useValue: new RuntimeService() },
        { provide: ICryptography, useValue: new RealCryptographyService() },
        { provide: IExecutionContext, useValue: new ExecutionContext() },
        { provide: ISerializer, useValue: new SerializerService() },
        { provide: GunDb, useClass: GunDb },
        { provide: IChannelService, useClass: ChannelService },
        { provide: IComputationTimeRepository, useClass: ComputationTimeRepository }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
