import { ICryptography } from '../../interfaces/icryptography';
import { Message, MessageTagsEnum, EncryptedPayload } from '../../interfaces/task-info';

export class MessageCryptoHelper {
    public static async encryptMessage(cryptographyService: ICryptography, theirPublicKey: CryptoKey, message: Message): Promise<Message> {
        if (message.tag === MessageTagsEnum.JOB_ADVERTIZING) {
            return message;
        }
        try {
            const encryptedPayload = await cryptographyService.encrypt(message.payload, theirPublicKey);
            const encryptedMessage = <Message>{
                correlationId: message.correlationId,
                tag: message.tag,
                payload: <EncryptedPayload>{
                    data: encryptedPayload
                },
                originNodeId: message.originNodeId
            };
            return encryptedMessage;
        }
        catch (e) {
            debugger;
            console.error(`Error while encrypting message: ${e}`);
            throw e;
        }
    }

    public static async decryptMessage(cryptographyService: ICryptography, message: Message): Promise<Message> {
        try {
            const encryptedPayload = <EncryptedPayload>message.payload;
            const decryptedPayload = await cryptographyService.decrypt(encryptedPayload.data);
            const decryptedMessage = <Message>{
                correlationId: message.correlationId,
                tag: message.tag,
                payload: decryptedPayload,
                originNodeId: message.originNodeId
            };
            return decryptedMessage;
        }
        catch (e) {
            debugger;
            console.error(`Error while decrypting message: ${e}`);
            throw e;
        }
    }
}
