import { IHRManager } from "../../interfaces/ihr-manager";
import {
    MessageTagsEnum,
    Message,
    JobAdvertizingPayload,
    TaskInfo,
    SignedContractPayload,
    ResultsPayload,
    ErrorPayload,
    JobApplicationPayload,
    JobOfferPayload,
} from "../../interfaces/task-info";
import { VolunteerStatesEnum } from "./volunteer-states.enum";
import { ICodeExecutorFactory } from "../../interfaces/icode-executor-factory";
import { IO2Cloud } from "../../interfaces/io2-cloud";
import { IVolunteerFlowFsm } from "../../interfaces/iflow-fsm";
import { TimeoutError } from "../../timeout-error";
import { FsmBase } from "./fsm-base";
import { CancellationToken } from "../../../core/cancellation-token";
import { ICryptography } from "../../interfaces/icryptography";
import { MessageCryptoHelper } from "./message-crypto-helper";
import { TimeoutTransitionArgs } from "./timeout-transition-args";
import { TimeoutsConfig } from "./timeouts-config";
import {
    PeerRelayEventKinds,
    DHT,
} from "../web-torrent-defs/bittorrent-dht/bittorrent-dht-defs";
import { IChannelService } from "../../interfaces/ichannel-service";
import { Roles } from "./roles";
import { IComputationTimeRepository } from "../../interfaces/Icomputation-time-repository";
import { AuthenticationService } from "src/app/services";
import { User } from "src/app/models/user";

export class VolunteerFsm extends FsmBase<VolunteerStatesEnum>
    implements IVolunteerFlowFsm {
    private task: TaskInfo | null = null;
    private results: any | null = null;
    private error: any | null = null;
    private startedAt: Date | null = null;
    private endedAt: Date | null = null;

    private static readonly INITIAL_CORRELATION_ID = null;

    public constructor(
        private readonly cloud: IO2Cloud,
        hrManager: IHRManager,
        private readonly correlationId2volunteerMap: Map<
            string,
            IVolunteerFlowFsm
        >,
        cryptographyService: ICryptography,
        shutdownToken: CancellationToken,
        private readonly codeExecutorFactory: ICodeExecutorFactory,
        protected readonly channelService: IChannelService,
        protected readonly computationTimeRepository: IComputationTimeRepository,
        private readonly authenticationService: AuthenticationService
    ) {
        super(
            Roles.VOLUNTEER,
            hrManager,
            cryptographyService,
            shutdownToken,
            VolunteerStatesEnum.INITIAL,
            VolunteerStatesEnum.TIMEOUT,
            channelService
        );

        this.setCorrelationId(VolunteerFsm.INITIAL_CORRELATION_ID);

        this.fsm
            .from(VolunteerStatesEnum.INITIAL)
            .to(
                VolunteerStatesEnum.STARTED,
                VolunteerStatesEnum.ERROR,
                VolunteerStatesEnum.TIMEOUT
            );

        this.fsm
            .from(VolunteerStatesEnum.STARTED)
            .to(
                VolunteerStatesEnum.RECEIVED_JOB_ADVERTIZING,
                VolunteerStatesEnum.ERROR,
                VolunteerStatesEnum.TIMEOUT
            );

        this.fsm
            .from(VolunteerStatesEnum.RECEIVED_JOB_ADVERTIZING)
            .to(
                VolunteerStatesEnum.SENT_JOB_APPLICATION,
                VolunteerStatesEnum.ERROR,
                VolunteerStatesEnum.TIMEOUT
            );

        this.fsm
            .from(VolunteerStatesEnum.SENT_JOB_APPLICATION)
            .to(
                VolunteerStatesEnum.RECEIVED_JOB_OFFER,
                VolunteerStatesEnum.REQUESTED_CANCELLATION,
                VolunteerStatesEnum.ERROR,
                VolunteerStatesEnum.TIMEOUT
            );

        this.fsm
            .from(VolunteerStatesEnum.RECEIVED_JOB_OFFER)
            .to(
                VolunteerStatesEnum.SENT_SIGNED_CONTRACT,
                VolunteerStatesEnum.REQUESTED_CANCELLATION,
                VolunteerStatesEnum.ERROR,
                VolunteerStatesEnum.TIMEOUT
            );

        this.fsm
            .from(VolunteerStatesEnum.SENT_SIGNED_CONTRACT)
            .to(
                VolunteerStatesEnum.WORKING,
                VolunteerStatesEnum.REQUESTED_CANCELLATION,
                VolunteerStatesEnum.ERROR,
                VolunteerStatesEnum.TIMEOUT
            );

        this.fsm
            .from(VolunteerStatesEnum.WORKING)
            .to(
                VolunteerStatesEnum.PRODUCED_RESULTS,
                VolunteerStatesEnum.REQUESTED_CANCELLATION,
                VolunteerStatesEnum.ERROR,
                VolunteerStatesEnum.TIMEOUT
            );

        this.fsm
            .from(VolunteerStatesEnum.PRODUCED_RESULTS)
            .to(
                VolunteerStatesEnum.SENT_RESULTS,
                VolunteerStatesEnum.REQUESTED_CANCELLATION,
                VolunteerStatesEnum.ERROR,
                VolunteerStatesEnum.TIMEOUT
            );

        this.fsm
            .from(VolunteerStatesEnum.SENT_RESULTS)
            .to(VolunteerStatesEnum.COMPLETED);

        this.fsm
            .from(VolunteerStatesEnum.REQUESTED_CANCELLATION)
            .to(
                VolunteerStatesEnum.SENT_CANCELLATION_CONFIRMATION,
                VolunteerStatesEnum.ERROR,
                VolunteerStatesEnum.TIMEOUT
            );

        this.fsm
            .from(VolunteerStatesEnum.SENT_CANCELLATION_CONFIRMATION)
            .to(
                VolunteerStatesEnum.CANCELLED,
                VolunteerStatesEnum.ERROR,
                VolunteerStatesEnum.TIMEOUT
            );

        this.fsm
            .from(VolunteerStatesEnum.CANCELLED)
            .to(
                VolunteerStatesEnum.COMPLETED,
                VolunteerStatesEnum.ERROR,
                VolunteerStatesEnum.TIMEOUT
            );

        this.fsm
            .from(VolunteerStatesEnum.TIMEOUT)
            .to(VolunteerStatesEnum.COMPLETED, VolunteerStatesEnum.ERROR);

        this.fsm
            .from(VolunteerStatesEnum.ERROR)
            .to(VolunteerStatesEnum.COMPLETED);

        this.fsm
            .from(VolunteerStatesEnum.COMPLETED)
            .to(VolunteerStatesEnum.STARTED, VolunteerStatesEnum.TERMINATED);

        this.fsm.onEnter(VolunteerStatesEnum.STARTED, this.started.bind(this));

        this.fsm.onEnter(
            VolunteerStatesEnum.RECEIVED_JOB_ADVERTIZING,
            this.sendJobApplication.bind(this)
        );
        this.fsm.onEnter(
            VolunteerStatesEnum.SENT_JOB_APPLICATION,
            this.startWaitingForSignedContract.bind(this)
        );
        this.fsm.onEnter(
            VolunteerStatesEnum.RECEIVED_JOB_OFFER,
            this.signContract.bind(this)
        );
        this.fsm.onEnter(
            VolunteerStatesEnum.SENT_SIGNED_CONTRACT,
            this.prepareForWork.bind(this)
        );

        this.fsm.onEnter(
            VolunteerStatesEnum.WORKING,
            this.startWorking.bind(this)
        );
        this.fsm.onEnter(
            VolunteerStatesEnum.PRODUCED_RESULTS,
            this.reportResults.bind(this)
        );
        this.fsm.onEnter(
            VolunteerStatesEnum.SENT_RESULTS,
            this.finishUp.bind(this)
        );

        this.fsm.onEnter(
            VolunteerStatesEnum.REQUESTED_CANCELLATION,
            this.cancel.bind(this)
        );
        this.fsm.onEnter(
            VolunteerStatesEnum.SENT_CANCELLATION_CONFIRMATION,
            this.reportResults.bind(this)
        );
        this.fsm.onEnter(
            VolunteerStatesEnum.CANCELLED,
            this.reportResults.bind(this)
        );

        this.fsm.onEnter(
            VolunteerStatesEnum.TIMEOUT,
            this.reportTimeout.bind(this)
        );
        this.fsm.onEnter(
            VolunteerStatesEnum.ERROR,
            this.reportError.bind(this)
        );

        this.fsm.onEnter(
            VolunteerStatesEnum.COMPLETED,
            this.cleanup.bind(this)
        );

        this.fsm.onEnter(
            VolunteerStatesEnum.TERMINATED,
            this.terminated.bind(this)
        );
    }

    public async start(): Promise<void> {
        this.channelService.myVolunteerPeer.on(
            PeerRelayEventKinds.MESSAGE,
            (data, from) => {
                setTimeout(() => {
                    if (data.buffer && typeof data.buffer === "string") {
                        const message = <Message>JSON.parse(data.buffer);
                        const originNodeId = from.toString("hex");
                        message.originNodeId = originNodeId;
                        this.onMessage(message);
                    }
                });
            }
        );

        await this.fsm.go(VolunteerStatesEnum.STARTED);
    }

    public onMessage(message: Message): boolean {
        console.log(
            `${this.role}[${message.correlationId}] | Handling ${message.tag}`
        );

        if (this.actingPeerId && this.actingPeerId != message.originNodeId) {
            return;
        }

        if (this.correlationId && this.correlationId != message.correlationId) {
            return;
        }

        if (
            !this.correlationId &&
            this.correlationId2volunteerMap.has(message.correlationId)
        ) {
            return;
        }

        console.debug(
            `${this.role} | received message from peer ${message.originNodeId}`,
            message
        );

        if (!this.correlationId) {
            if (message.tag === MessageTagsEnum.JOB_ADVERTIZING) {
                this.setCorrelationId(message.correlationId);
                this.correlationId2volunteerMap.set(
                    message.correlationId,
                    this
                );
                this.actingPeerId = message.originNodeId;
                this.scheduleTransitionTo(
                    "onMessage",
                    VolunteerStatesEnum.RECEIVED_JOB_ADVERTIZING,
                    message
                );
                return true;
            }

            return false;
        }

        if (message.correlationId !== this.correlationId) {
            return false;
        }

        switch (message.tag) {
            case MessageTagsEnum.JOB_OFFER:
                this.setImmediate(async () => {
                    const decryptedMessage = await MessageCryptoHelper.decryptMessage(
                        this.cryptographyService,
                        message
                    );
                    console.log(
                        `${this.role} | << onMessage -> received-job-offer`
                    );
                    this.scheduleTransitionTo(
                        "onMessage",
                        VolunteerStatesEnum.RECEIVED_JOB_OFFER,
                        decryptedMessage
                    );
                });
                return true;

            default:
                return false;
        }
    }

    private async started(status: any): Promise<void> {
        this.monitorTimeout(
            TimeoutsConfig.lookingForJobTimeoutMs,
            `${this.role} | Starting Node in ${TimeoutsConfig.lookingForJobTimeoutMs} ms"`
        );
        this.startedAt = this.endedAt = new Date();
    }

    private async sendJobApplication(
        state: any,
        message: Message
    ): Promise<void> {
        console.log(`${this.role} | >> sendJobApplication`);
        this.stopMonitoringTimeout();
        this.monitorTimeout(
            TimeoutsConfig.messagingTimeoutMs,
            `${this.role} | Send job application ${TimeoutsConfig.messagingTimeoutMs} ms`
        );

        const payload = <JobAdvertizingPayload>message.payload;
        await this.importTheirPublicKey(payload.publicKey);

        const myPublicKey = await this.cryptographyService.myPublicKey();
        const messageToSend = <Message>{
            correlationId: this.correlationId,
            tag: MessageTagsEnum.JOB_APPLICATION,
            payload: <JobApplicationPayload>{
                publicKey: myPublicKey,
                requiredCapabilities: payload.requiredCapabilities,
            },
        };
        await this.sendMessage(
            this.actingPeerId,
            await MessageCryptoHelper.encryptMessage(
                this.cryptographyService,
                this.theirPublicKey,
                messageToSend
            )
        );

        console.log(
            `${this.role} | << sendJobApplication -> sent-job-application`
        );
        this.scheduleTransitionTo(
            "sendJobApplication",
            VolunteerStatesEnum.SENT_JOB_APPLICATION
        );
    }

    private async startWaitingForSignedContract(state: any): Promise<void> {
        console.log(`${this.role} | >> startWaitingForSigtnedContract`);
        this.stopMonitoringTimeout();
        this.monitorTimeout(
            TimeoutsConfig.messagingTimeoutMs,
            `${this.role} | Start waiting for signed contract ${TimeoutsConfig.messagingTimeoutMs} ms`
        );
    }

    private async signContract(state: any, message: Message): Promise<void> {
        console.log(`${this.role} | >> signContract`);
        this.stopMonitoringTimeout();
        this.monitorTimeout(
            TimeoutsConfig.messagingTimeoutMs,
            `${this.role} | Sign contract in ${TimeoutsConfig.messagingTimeoutMs} ms`
        );

        const payload = <JobOfferPayload>message.payload;
        this.task = payload.task;

        const messageToSend = <Message>{
            correlationId: this.correlationId,
            tag: MessageTagsEnum.SIGNED_CONTRACT,
            payload: <SignedContractPayload>{},
        };
        await this.sendMessage(
            this.actingPeerId,
            await MessageCryptoHelper.encryptMessage(
                this.cryptographyService,
                this.theirPublicKey,
                messageToSend
            )
        );

        console.log(`${this.role} | << signContract -> sent-signed-contract`);
        this.scheduleTransitionTo(
            "signContract",
            VolunteerStatesEnum.SENT_SIGNED_CONTRACT
        );
    }

    private async prepareForWork(state: any): Promise<void> {
        console.log(`${this.role} | << prepareForWork => working`);
        this.scheduleTransitionTo(
            "prepareForWork",
            VolunteerStatesEnum.WORKING
        );
    }

    private async startWorking(state: any): Promise<void> {
        console.log(`${this.role} | >> startWorking`);
        this.setImmediate(async () => {
            try {
                const codeExecutor = this.codeExecutorFactory.getExecutor(
                    this.task,
                    this.cloud
                );
                this.monitorTimeout(
                    this.task.timeout,
                    `${this.role} | Start working in ${this.task.timeout} ms`
                );
                this.startedAt = this.endedAt = new Date();
                this.results = await codeExecutor.executeCode(
                    this.task,
                    this.cloud
                );
                console.log(
                    `${this.role} | << startWorking -> produced-results`
                );
                this.scheduleTransitionTo(
                    "startWorking",
                    VolunteerStatesEnum.PRODUCED_RESULTS
                );
            } catch (e) {
                console.error(`${this.role} | << startWorking -> error`, e);
                this.error = e;
                this.scheduleTransitionTo(
                    "startWorking",
                    VolunteerStatesEnum.ERROR
                );
            }
        });
    }

    private async reportResults(state: any): Promise<void> {
        console.log(`${this.role} | >> reportResults`);
        this.log(MessageTagsEnum.RESULTS_READY);

        this.monitorTimeout(
            TimeoutsConfig.messagingTimeoutMs,
            `${this.role} | Report results in ${TimeoutsConfig.messagingTimeoutMs} ms`
        );

        this.endedAt = new Date();

        const elapsedMs = this.endedAt.getTime() - this.startedAt.getTime();

        const messageToSend = <Message>{
            correlationId: this.correlationId,
            tag: MessageTagsEnum.RESULTS_READY,
            payload: <ResultsPayload>{
                results: this.results,
                elapsedMs: elapsedMs,
            },
        };
        await this.sendMessage(
            this.actingPeerId,
            await MessageCryptoHelper.encryptMessage(
                this.cryptographyService,
                this.theirPublicKey,
                messageToSend
            )
        );

        const currentUser = this.authenticationService.currentUserValue;

        // if (currentUser) {
        //     currentUser.balance = this.computationTimeRepository.increase(
        //         currentUser.balance,
        //         elapsedMs
        //     );

        //     await this.authenticationService.update(currentUser);
        // }

        console.log(`${this.role} | << reportResults -> sent-results`);
        this.scheduleTransitionTo(
            "reportResults",
            VolunteerStatesEnum.SENT_RESULTS
        );
    }

    private async finishUp(state: any): Promise<void> {
        console.log(`${this.role} | >> finishUp`);
        this.scheduleTransitionToNoTimeout(
            "finishUp",
            VolunteerStatesEnum.COMPLETED
        );
    }

    private async reportTimeout(
        state: any,
        args: TimeoutTransitionArgs
    ): Promise<void> {
        console.log(`${this.role} | >> reportTimeout`);
        this.error = this.error || new TimeoutError(args.cause);
        console.warn(`Timeout reported for "${args.cause}": ${this.error}`);

        if (args.doReport && this.actingPeerId) {
            try {
                const messageToSend = <Message>{
                    correlationId: this.correlationId,
                    tag: MessageTagsEnum.TIMEOUT,
                    payload: <ErrorPayload>{
                        error: this.error,
                        elapsedMs:
                            this.endedAt.getTime() - this.startedAt.getTime(),
                    },
                };
                await this.sendMessage(
                    this.actingPeerId,
                    await MessageCryptoHelper.encryptMessage(
                        this.cryptographyService,
                        this.theirPublicKey,
                        messageToSend
                    )
                );
            } catch (e) {
                console.error(`${this.role} | << reportTimeout -> error`);
                this.error = e;
                this.scheduleTransitionToNoTimeout(
                    "reportTimeout",
                    VolunteerStatesEnum.ERROR
                );
                return;
            }
        }

        console.log(`${this.role} | << reportTimeout -> completed`);
        this.scheduleTransitionToNoTimeout(
            "reportTimeout",
            VolunteerStatesEnum.COMPLETED
        );
    }

    private async reportError(state: any): Promise<void> {
        console.log(`${this.role} | >> reportError`);
        this.endedAt = new Date();
        if (this.actingPeerId) {
            const messageToSend = <Message>{
                correlationId: this.correlationId,
                tag: MessageTagsEnum.ERROR,
                payload: <ErrorPayload>{
                    error: this.error,
                    elapsedMs:
                        this.endedAt.getTime() - this.startedAt.getTime(),
                },
            };
            await this.sendMessage(
                this.actingPeerId,
                await MessageCryptoHelper.encryptMessage(
                    this.cryptographyService,
                    this.theirPublicKey,
                    messageToSend
                )
            );
        }

        this.scheduleTransitionTo("reportError", VolunteerStatesEnum.COMPLETED);
    }

    private async cancel(state: any): Promise<void> {
        console.log(`${this.role} | >> cancel`);
        this.endedAt = new Date();
        //this.shutdownToken.cancel();
    }

    public async cleanup(state: any): Promise<void> {
        console.log(`${this.role} | >> cleanup`);
        if (!this.correlationId2volunteerMap.has(this.correlationId)) {
            //debugger;
        }

        this.correlationId2volunteerMap.delete(this.correlationId);
        this.setCorrelationId(VolunteerFsm.INITIAL_CORRELATION_ID);

        try {
            this.task = null;
            this.results = null;
            this.actingPeerId = null;
            this.setCorrelationId(null);
        } catch (e) {
            console.error(e);
        }

        this.stopMonitoringTimeout();
        if (this.shutdownToken.isCancelled) {
            console.log(`${this.role} | << cleanup -> terminated`);
            this.scheduleTransitionToNoTimeout(
                "cleanup",
                VolunteerStatesEnum.TERMINATED
            );
        } else {
            console.log(`${this.role} | << cleanup -> starting`);
            this.scheduleTransitionToNoTimeout(
                "cleanup",
                VolunteerStatesEnum.STARTED
            );
        }
    }

    private async terminated(state: any): Promise<void> {
        this.stopMonitoringTimeout();
        console.log(`${this.role}[${this.correlationId}] | Terminated`);
    }
}
