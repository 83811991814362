import { IDisposableAsync, IDisposable } from '../../misc/interfaces/idisposable';
import { TaskInfo } from './task-info';

export abstract class IO2Cloud extends IDisposableAsync {
    public abstract get nodeId(): string;
    public abstract start(channelIds: any[]): Promise<IDisposable>;

    public abstract get elapsedMs(): number;
    public abstract get totalCores(): number;
    public abstract set totalCores(value: number);
    public abstract get usedCores(): number;
    public abstract get availableCores(): number;

    public abstract get runningRemoteTasks(): number;
    public abstract get runningTasks(): number;
    public abstract get isFullyLoaded(): boolean;

    public abstract executeTask<R = void>(task: TaskInfo, jobPortalId?: string): Promise<R>;
    public abstract executeAllTasks<R = void>(tasks: TaskInfo[], jobPortalId?: string): Promise<R[]>;
    public abstract executeAnyTask<R = void>(tasks: TaskInfo[], jobPortalId?: string): Promise<R>;

}
