import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'footer',
    templateUrl: './footer.component.html',
    host: {'class': 'page-footer'}
})
export class FooterComponent implements OnInit {
    currentYear = (new Date).getFullYear();

    constructor(
    ) {
    }

    ngOnInit() {
    }
}
