import { ICodeExecutor } from '../../../interfaces/icode-executor';
import { isPromiseAlike } from 'q';
import { IO2Cloud } from '../../../interfaces/io2-cloud';
import { TaskInfo } from '../../../interfaces/task-info';

export class WebAssemblyUrlCodeExecutor implements ICodeExecutor {

    private constructor(
        public readonly location: string,
        private readonly exports: any
    ) {
    }

    public static async create(task: TaskInfo, cloud: IO2Cloud): Promise<ICodeExecutor> {
        const response = await fetch(task.codeLocation as string);
        const bytes = await response.arrayBuffer();
        const results = await WebAssembly.instantiate(bytes, task.imports);
        const exports = results.instance.exports;
        const codeExecutor = new WebAssemblyUrlCodeExecutor(task.codeLocation as string, exports);
        return codeExecutor;
    }

    public async executeCode(task: TaskInfo, cloud: IO2Cloud): Promise<any> {
        try {
            const actionFunc = this.exports[task.actionToInvoke];
            const resultsOrFuture = actionFunc.apply(window, task.args);
            let results = null;
            if (resultsOrFuture) {
                if (isPromiseAlike(resultsOrFuture)) {
                    results = await resultsOrFuture;
                } else {
                    results = resultsOrFuture;
                }
            }
            return results;
        }
        catch (e) {
            debugger;
            console.error(e);
            throw e;
        }
    }

    public dispose(): Promise<void> {
        return Promise.resolve();
    }
}
