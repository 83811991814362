import { IDisposableAsync, IDisposable } from '../../misc/interfaces/idisposable';

export interface IMap<K, V> {
    clear(): void;
    delete(k: any): boolean;
    forEach(callbackfn: (value: V, key: K, map: Map<K, V>) => void, thisArg?: any): void;
    get(k: K): V;
    has(k: K): boolean;
    set(k: K, v: V): this;
    size: number;
    entries(): IterableIterator<[K, V]>;
    keys(): IterableIterator<K>;
    values(): IterableIterator<V>;
}

export class GcMap<K, V> implements IDisposableAsync, IMap<K, V> {
    public static readonly DEFAULT_TTL = 1000;

    private readonly impl = new Map<K, V>();
    private readonly ttls = new Map<K, number>();
    private readonly gcHandle: any;
    public breakOnDeleteOrDispose = false;

    public onChanged: (source: GcMap<K, V>) => void;

    private notifyChanged() {
        if (this.onChanged) {
            this.onChanged(this);
        }
    }

    private resetTtl(k: K) {
        if (this.impl.has(k)) {
            this.ttls.set(k, Date.now() + this.gcInterval);
        }
        else if (this.ttls.has(k)) {
            debugger;
            this.ttls.delete(k);
        }
    }

    public constructor(
        public readonly name: string,
        private readonly gcInterval: number = GcMap.DEFAULT_TTL,
        entries?: ReadonlyArray<[K, V]> | null
    ) {
        this.impl = new Map<K, V>(entries);
        this.gcHandle = setInterval(
            async () => {
                const entries = Array.from(this.ttls.entries());
                const expiredEntries: [K, number][] = entries.filter(([k, ttl], i, a) => Date.now() >= ttl);
                //if (entries.length != expiredEntries.length && expiredEntries.length > 0) debugger;
                for (let [k, number] of expiredEntries) {
                    await this.deleteAndDispose(k);
                }
            },
            1000
        );
    }

    public clear(): void {
        this.impl.clear();
        this.ttls.clear();
    }

    public async clearAndDispose(): Promise<void> {
        if (this.breakOnDeleteOrDispose) debugger;
        debugger;
        try {
            const vs = Array.from(this.impl.entries());
            this.impl.clear();
            this.ttls.clear();
            for (let v of vs) {
                await this.disposeIt(v);
            }
        }
        finally {
            this.notifyChanged();
        }
    }

    public delete(k: K): boolean {
        if (this.breakOnDeleteOrDispose) debugger;
        debugger;
        try {
            this.ttls.delete(k);

            const oldCount = this.impl.size;
            const b = this.impl.delete(k);
            const newCount = this.impl.size;
            if (newCount >= oldCount) debugger;

            return b;
        }
        finally {
            this.notifyChanged();
        }
    }

    public async deleteAndDispose(k: K): Promise<boolean> {
        if (this.breakOnDeleteOrDispose) debugger;
        try {
            const v = this.impl.get(k);
            this.ttls.delete(k);

            const oldCount = this.impl.size;
            const b = this.impl.delete(k);
            const newCount = this.impl.size;
            if (newCount >= oldCount) debugger;

            if (v) {
                await this.disposeIt(v);
            }
            return b;
        }
        finally {
            this.notifyChanged();
        }
    }

    public forEach(callbackfn: (value: V, key: K, map: Map<K, V>) => void, thisArg?: any): void {
        this.impl.forEach(
            (v: V, k: K, m: Map<K, V>) => {
                this.resetTtl(k);
                callbackfn(v, k, m);
            },
            thisArg
        );
    }

    public get(k: K): V {
        this.resetTtl(k);
        return this.impl.get(k);
    }

    public has(k: K): boolean {
        //this.resetTtl(k);
        return this.impl.has(k);
    }

    public set(k: K, v: V): this {
        try {
            this.impl.set(k, v);
            this.resetTtl(k);
            return this;
        }
        finally {
            this.notifyChanged();
        }
    }

    public get size(): number {
        return this.impl.size;
    }

    entries(): IterableIterator<[K, V]> {
        this.impl.forEach((v, k, m) => this.resetTtl(k));
        return this.impl.entries();
    }

    keys(): IterableIterator<K> {
        this.impl.forEach((v, k, m) => this.resetTtl(k));
        return this.impl.keys();
    }

    values(): IterableIterator<V> {
        this.impl.forEach((v, k, m) => this.resetTtl(k));
        return this.impl.values();
    }

    public async dispose(): Promise<void> {
        try {
            clearInterval(this.gcHandle);

            for (let k of Array.from(this.impl.keys())) {
                if (this.impl.has(k)) {
                    const v = this.impl.get(k);

                    this.impl.delete(k);
                    this.ttls.delete(k);

                    await this.disposeIt(v);
                }
            }
        }
        finally {
            this.notifyChanged();
        }
    }

    private async disposeIt(v: any) {
        if (!v) return;
        const traversed = new Set<any>();
        const q: any[] = [v];
        while (q.length > 0) {
            const x = q.pop();
            if (!x) continue;
            if (traversed.has(x)) continue;
            traversed.add(x);
            if (x instanceof IDisposableAsync) {
                await x.dispose();
            }
            else if (x instanceof IDisposable) {
                x.dispose();
            }
            else {
                for (let k of Object.getOwnPropertyNames(x)) {
                    const y = x[k];
                    if (y) {
                        q.push(y);
                    }
                }
            }
        }
        traversed.clear();
    }
}
