import { Injectable } from '@angular/core';
import { ISerializer } from '../flow/interfaces/iserializer';

@Injectable({
  providedIn: 'root'
})
export class SerializerService implements ISerializer {
  public serialize(data: any): string {
    return JSON.stringify(data);
  }
  public deserialize(data: string): any {
    return JSON.parse(data);
  }
}
