import { Component, OnInit } from '@angular/core';
import { IO2Cloud } from '../p2p-cloud/flow/interfaces/io2-cloud';
import { TaskInfo, CodeTypesEnum } from '../p2p-cloud/flow/interfaces/task-info';
import { delay } from 'q';
import { DHT } from '../p2p-cloud/flow/impls/web-torrent-defs/bittorrent-dht/bittorrent-dht-defs';

@Component({
    selector: 'demo-prime-numbers',
    templateUrl: './demo-prime-numbers.component.html',
    host: { 'class': 'demo-component' },
})
export class DemoPrimeNumbersComponent implements OnInit {
    progressValue: number;
    private oldProgressValue: number;
    room: string;
    editorOptions = {
        theme: 'vs-light',
        language: 'javascript',
        automaticLayout: true
    };
    isEditorFullscreen = false;
    isCanvasFullscreen = false;
    numbers = [];
    isStarted = false;
    code: string;
    log: string;
    channel: DHT;

    public get availabilityStatus(): string {
        return `Can${(this.cloud.isFullyLoaded ? "not" : "")}`;
    }

    constructor(public readonly cloud: IO2Cloud) {
    }

    async ngOnInit() {
        this.numbers = Array(1000).fill(0).map((x, i) => i);
        this.progressValue = 0;
        const codeResponse = await fetch('https://allconnectix.visualstudio.com/defaultcollection/algorithms/_apis/git/repositories/algorithms/items?api-version=1.0&scopepath=demo/prime-numbers/prime-numbers-simple.ts');
        this.code = await codeResponse.text();
    }

    async onExecuteOnce() {
        const maxLimit = 10 * 100;
        await this.initializeNumbers(maxLimit);

        try {
            const primes = await this.executePrimesNtimesParallel(maxLimit, 1, 10);

            console.log(`Prime Numbers up to ${maxLimit} are: ${(JSON.stringify(primes))}`);

            // for (let i = 1; i < maxLimit; i++) {
            //     await this.setNumberStyle(i, false, maxLimit, 0);
            // }

            this.log += "\r\nStarted showing results...";

            for (let i = 0; i < primes.length; i++) {
                await this.setNumberStyle(primes[i], true, primes.length, i + 1);
            }

            this.log += "\r\nDone!";

            console.log(`Available peers: ${this.cloud.availableCores}.`);
        }
        catch (e) {
            debugger;
            console.error(e);
        }
    }

    async onSubmitBasic() {
        this.log = "Started";
        this.isStarted = true;

        await this.onExecuteOnce();

        this.isStarted = false;

        return;

        const maxLimit = 10000;
        await this.initializeNumbers(maxLimit);//1000);
        const timesToExecute = 10;//50;
        try {
            const primeNumbers1 = await this.measureTime("Primes parallel", timesToExecute, () => this.executePrimesNtimesParallel(maxLimit, timesToExecute, null));
            const primeNumbers2 = await this.measureTime("Primes sequential", timesToExecute, () => this.executePrimesNtimesSequential(maxLimit, timesToExecute, null));

            for (let i = 1; i < maxLimit; i++) {
                await this.setNumberStyle(i, false, maxLimit, 0);
            }

            for (let i = 0; i < primeNumbers1.result.length; i++) {
                await this.setNumberStyle(primeNumbers1.result[i], true, primeNumbers1.result.length, i + 1);
            }

            console.log(`Prime Numbers up to ${maxLimit} are: ${(JSON.stringify(primeNumbers1.result))}`);

            console.log(`  Parallel ${timesToExecute} times.\nElapsed:\n\t${primeNumbers1.totalTimeMs} ms total.\n\t${primeNumbers1.timePerIterationMs} ms per iteration.`);
            console.log(`Sequential ${timesToExecute} times.\nElapsed:\n\t${primeNumbers2.totalTimeMs} ms total.\n\t${primeNumbers2.timePerIterationMs} ms per iteration.`);

            console.log(`Available peers: ${this.cloud.availableCores}.`);
        }
        catch (e) {
            debugger;
            console.error(e);
        }
    }

    private async measureTime<R>(
        title: string,
        timesToExecute: number,
        callback: () => Promise<R>
    ): Promise<{ result: R, totalTimeMs: number, timePerIterationMs: number }> {
        const timeScope = `Total time of "${title}"`;
        console.time(timeScope);
        const startTimestamp = Date.now();
        const result = await callback();
        const endTimestamp = Date.now();
        console.timeEnd(timeScope);
        const totalTimeMs = endTimestamp - startTimestamp;
        const timePerIterationMs = totalTimeMs / timesToExecute;
        return { result, totalTimeMs, timePerIterationMs };
    }

    private async executePrimesNtimesParallel(maxLimit: number, times: number, retryCount: number): Promise<number[]> {
        if (maxLimit < 100) throw new Error(`Invalid maxLimit: ${maxLimit}`);
        if (times <= 0 || times > 1000) throw new Error(`Invalid times: ${times}`);

        const futures: Promise<number[]>[] = [];
        for (let i = 0; i < times; ++i) {
            const future: Promise<number[]> = this.cloud.executeTask<number[]>(
                <TaskInfo>{
                    codeType: CodeTypesEnum.SOURCE_TS,
                    codeLocation: this.code,
                    actionToInvoke: 'primeNumbers.run',
                    args: [maxLimit],
                    timeout: 10 * 1000,
                    retryCount: retryCount
                },
                null
            );
            futures.push(future);
        }

        this.log += "\r\nExecuting tasks...";

        const results = await Promise.all(futures);

        this.log += "\r\nExecuted tasks!";

        return results[0];
    }

    private async executePrimesNtimesSequential(maxLimit: number, times: number, retryCount: number): Promise<number[]> {
        if (maxLimit < 100) throw new Error(`Invalid maxLimit: ${maxLimit}`);
        if (times <= 0 || times > 1000) throw new Error(`Invalid times: ${times}`);

        let result: number[];
        for (let i = 0; i < times; ++i) {
            result = await this.cloud.executeTask<number[]>(
                <TaskInfo>{
                    codeType: CodeTypesEnum.SOURCE_TS,//_URL,
                    codeLocation: this.code,//'https://allconnectix.visualstudio.com/defaultcollection/algorithms/_apis/git/repositories/algorithms/items?api-version=1.0&scopepath=demo/prime-numbers/prime-numbers-simple.ts',
                    actionToInvoke: 'primeNumbers.run',
                    args: [
                        maxLimit
                        // async (i: number, isPrime: boolean) => { await delay(1); console.log(`${i} => ${isPrime}`); }
                        // async (i: number, isPrime: boolean) => {
                        //     console.log(`${i} => ${isPrime}`);
                        //     await this.setNumberStyle(i, isPrime);
                        // }
                    ],
                    retryCount: retryCount,
                    timeout: 10 * 60 * 1000,
                },
                null
            );
        }

        return result;
    }

    async setNumberStyle(number: number, isPrime: boolean, primeNumbersCount: number, currentProgressPosition: number) {
        if (number > this.numbers.length) return;

        let numberObj = this.numbers[number - 1];

        numberObj.type = isPrime ? 4 : 2;
        numberObj.value = number;

        this.progressValue = Math.round(100 / (primeNumbersCount / currentProgressPosition)) | 0;
        if (isPrime && (this.oldProgressValue !== this.progressValue)) {
            await delay(0.1);
        }
        this.oldProgressValue = this.progressValue;
    }

    async setCurrentPosition(number: number) {
        const numberItem = document.getElementById('item' + number);
        if (!numberItem || !numberItem.parentElement || !numberItem.parentElement.parentElement) {
            return;
        }
        const parent = numberItem.parentElement.parentElement;

        const offsetTop = numberItem.offsetTop;
        const offsetLeft = numberItem.offsetLeft;

        if (!(parent.scrollTop < offsetTop && parent.scrollTop + 600 > offsetTop)) {
            parent.scrollTo(offsetLeft, offsetTop);
        }
    }

    async initializeNumbers(maxNum: number) {
        this.progressValue = 0;
        let currentNumber = 1;
        this.numbers = Array(maxNum).fill(0).map(() => <PrimeEntry>{ value: currentNumber++, type: 0 });
    }

    toggleEditorFullscreen() {
        this.isEditorFullscreen = !this.isEditorFullscreen;
    }

    toggleCanvasFullscreen() {
        this.isCanvasFullscreen = !this.isCanvasFullscreen;
    }
}

interface PrimeEntry {
    value: number,
    type: number
}
