import { Injectable } from '@angular/core';
import { ICryptography } from '../flow/interfaces/icryptography';
import { KeysPair, InternalAsymmetricCryptoApi } from './internal-asymmetric-crypto-api';
import { InternalCryptoFacade } from './internal-crypto-facade';
import { catchAndLog } from './catch-and-log';

export interface KeysData {
  privateKey: CryptoKey;
  publicKey: CryptoKey;
  exportedPublicKey: string;
}

@Injectable()
export class RealCryptographyService implements ICryptography {

  private keysFuture: Promise<KeysData> = null;
  private keys: KeysData = null;

  public constructor() {
    this.keysFuture = this.generateKeys();
  }
  public async hashOf(obj: any): Promise<string> {
    return await InternalCryptoFacade.hashOf(obj);
  }

  public async myPublicKey(): Promise<string> {
    return await catchAndLog("RealCryptographyService.myPublicKey", async () => {
      if (!this.keys) {
        this.keys = await this.keysFuture;
      }
      return this.keys.exportedPublicKey;
    });
  }

  public async importKey(publicKey: string): Promise<CryptoKey> {
    return await catchAndLog("RealCryptographyService.importKey", async () => {
      const importedKey = await this.internalImportKey(publicKey);
      return importedKey;
    });
  }

  public async encrypt(data: any, publicKey: CryptoKey): Promise<string> {
    return await catchAndLog("RealCryptographyService.encrypt", async () => {
      const blob = await InternalCryptoFacade.encryptObject(publicKey, data);
      const jsonData = JSON.stringify(blob);
      return jsonData;
    });
  }

  public async decrypt(data: string): Promise<any> {
      return await catchAndLog("RealCryptographyService.decrypt", async () => {
          try {
              const blob = JSON.parse(data);
              const obj = await InternalCryptoFacade.decryptObject(this.keys.privateKey, blob);
              return obj;
          }
          catch (e) {
              debugger;
              throw e;
        }
    });
  }

  private async generateKeys(): Promise<KeysData> {
    return await catchAndLog("RealCryptographyService.generateKeys", async () => {
      const keyPair = await this.internalGenerateKeyPair();
      const exportedKey = await this.internalExportKey(keyPair.publicKey)
      const keys = <KeysData>{
        privateKey: keyPair.privateKey,
        publicKey: keyPair.publicKey,
        exportedPublicKey: exportedKey
      };
      return keys;
    });
  }

  private async internalGenerateKeyPair(): Promise<KeysPair> {
    return await catchAndLog("RealCryptographyService.internalGenerateKeyPair", async () => {
      return InternalAsymmetricCryptoApi.generateKeyPair();
    });
  }

  private internalExportKey(nativeKey: CryptoKey): Promise<string> {
    return catchAndLog("RealCryptographyService.internalExportKey", () => {
      return InternalAsymmetricCryptoApi.exportKey(nativeKey);
    });
  }

  private internalImportKey(base64Key: string): Promise<CryptoKey> {
    return catchAndLog("RealCryptographyService.internalImportKey", () => {
      return InternalAsymmetricCryptoApi.importKey(base64Key);
    });
  }
}
