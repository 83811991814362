import { ICodeExecutor } from '../../../interfaces/icode-executor';
import { isPromiseAlike } from 'q';
import { TaskInfo } from '../../../interfaces/task-info';
import { IO2Cloud } from '../../../interfaces/io2-cloud';

export class SourceCodeExecutor implements ICodeExecutor {
    public async executeCode(task: TaskInfo, cloud: IO2Cloud): Promise<any> {
        const codeStr = `(function() { return ${task.codeLocation}; })()`;
        const code = eval(codeStr);
        const action = task.actionToInvoke && task.actionToInvoke.length > 0
            ? code[task.actionToInvoke]
            : code;
        const resultsOrFuture = action.apply(window, [cloud, ...task.args]);
        let results = null;
        if (resultsOrFuture) {
            if (isPromiseAlike(resultsOrFuture)) {
                results = await resultsOrFuture;
            } else {
                results = resultsOrFuture;
            }
        }
        return results;
    }

    public dispose(): Promise<void> {
        return Promise.resolve();
    }
}
