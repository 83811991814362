import { InternalSymmetricCryptoApi } from './internal-symmetric-crypto-api';
import { InternalAsymmetricCryptoApi } from './internal-asymmetric-crypto-api';
import { TextUtils } from './text-utils';
import { Base64 } from 'js-base64';
import { catchAndLog } from './catch-and-log';

export class InternalCryptoFacade {
    public static async encryptObject(
        publicKey: CryptoKey,
        inputData: any
    ): Promise<AsymmetricEncryptedBlob> {
        return await catchAndLog("InternalCryptoFacade.encryptObject", async () => {
            const json = JSON.stringify(inputData);
            const inputBinary = TextUtils.string2arrayBuffer(json);
            const encryptedData = await InternalCryptoFacade.encrypt(publicKey, inputBinary);
            return encryptedData;
        });
    }

    public static async decryptObject(
        privateKey: CryptoKey,
        blob: AsymmetricEncryptedBlob
    ): Promise<any> {
        return await catchAndLog("InternalCryptoFacade.decryptObject", async () => {
            const decryptedBinary = await InternalCryptoFacade.decrypt(privateKey, blob);
            const json = TextUtils.arrayBuffer2string(decryptedBinary);
            const obj = JSON.parse(json);
            return obj;
        });
    }

    public static async hashOf(obj): Promise<string> {
        const str = JSON.stringify(obj);
        const encoder = new TextEncoder();
        const data = encoder.encode(str);
        const hash = await crypto.subtle.digest('SHA-512', data);
        const hashStr = new TextDecoder().decode(hash);
        return hashStr;
      }
      

    public static async encrypt(
        publicKey: CryptoKey,
        inputData: Int8Array | Int16Array | Int32Array | Uint8Array | Uint16Array | Uint32Array | Uint8ClampedArray | Float32Array | Float64Array | DataView | ArrayBuffer
    ): Promise<AsymmetricEncryptedBlob> {
        return await catchAndLog("InternalCryptoFacade.encrypt", async () => {
            const symmetricKey = await InternalSymmetricCryptoApi.generateKey();
            const encryptedData = await InternalSymmetricCryptoApi.encrypt(symmetricKey, inputData);

            const encryptedSymmetricCounter = await InternalAsymmetricCryptoApi.encrypt(publicKey, encryptedData.counter);
            const encryptedSymmetricKey = await InternalSymmetricCryptoApi.wrapKey(symmetricKey, publicKey);

            const strKey = TextUtils.arrayBuffer2string(encryptedSymmetricKey);
            const strCounter = TextUtils.arrayBuffer2string(encryptedSymmetricCounter);
            const strData = TextUtils.arrayBuffer2string(encryptedData.encrypted);

            const base64key = Base64.encode(strKey);
            const base64counter = Base64.encode(strCounter);
            const base64data = Base64.encode(strData);

            return <AsymmetricEncryptedBlob>{
                k: base64key,
                c: base64counter,
                v: base64data
            };
        });
    }

    public static async decrypt(
        privateKey: CryptoKey,
        blob: AsymmetricEncryptedBlob
    ): Promise<any> {
        return await catchAndLog("InternalCryptoFacade.decrypt", async () => {
            const strSymmetricKey = Base64.decode(blob.k);
            const strSymmetricCounter = Base64.decode(blob.c);
            const strData = Base64.decode(blob.v);

            const encryptedSymmetricKey = TextUtils.string2arrayBuffer(strSymmetricKey);
            const encryptedSymmetricCounter = TextUtils.string2arrayBuffer(strSymmetricCounter);
            const encryptedData = TextUtils.string2arrayBuffer(strData);

            const symmetricKey = await InternalSymmetricCryptoApi.unwrapKey(encryptedSymmetricKey, privateKey);
            const symmetricCounter = await InternalAsymmetricCryptoApi.decrypt(privateKey, encryptedSymmetricCounter);

            const decryptedData = await InternalSymmetricCryptoApi.decrypt(symmetricKey, {
                counter: symmetricCounter,
                encrypted: encryptedData
            });

            return decryptedData;
        });
    }
}

export interface AsymmetricEncryptedBlob {
    k: string;
    c: string;
    v: string;
}
