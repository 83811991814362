import { FsmBase } from "./fsm-base";
import { EmployerStatesEnum } from "./employer-states.enum";
import { IEmployerFlowFsm } from "../../interfaces/iflow-fsm";
import { IHRManager } from "../../interfaces/ihr-manager";
import {
    TaskInfo,
    Message,
    MessageTagsEnum,
    JobAdvertizingPayload,
    JobApplicationPayload,
    ResultsPayload,
    ErrorPayload,
    JobOfferPayload,
} from "../../interfaces/task-info";
import { ResolvablePromise } from "../../../core/resolvable-promise";
import { CancellationToken } from "../../../core/cancellation-token";
import { UUID } from "angular2-uuid";
import { TimeoutError } from "../../timeout-error";
import { ICryptography } from "../../interfaces/icryptography";
import { MessageCryptoHelper } from "./message-crypto-helper";
import { TimeoutsConfig } from "./timeouts-config";
import {
    PeerRelayEventKinds,
    LookupAbortFunc,
    PeerInfo,
} from "../web-torrent-defs/bittorrent-dht/bittorrent-dht-defs";
import { IChannelService } from "../../interfaces/ichannel-service";
import { Roles } from "./roles";
import { IComputationTimeRepository } from "../../interfaces/Icomputation-time-repository";
import { AuthenticationService } from 'src/app/services';
import { first } from 'rxjs/operators';

export class EmployerFsm<R = void> extends FsmBase<EmployerStatesEnum>
    implements IEmployerFlowFsm {
    private elapsedMs_: number = null;
    private readonly channelIdsHashes: any = {};
    private stopLookingForPeers: LookupAbortFunc[] = [];
    private onMessageEvent: any;

    public constructor(
        hrManager: IHRManager,
        cryptographyService: ICryptography,
        channelIdsHashes: any[],
        private task: TaskInfo,
        private resultsFuture: ResolvablePromise<R>,
        shutdownToken: CancellationToken,
        protected readonly channelService: IChannelService,
        protected readonly computationTimeRepository: IComputationTimeRepository,
        private readonly authenticationService: AuthenticationService
    ) {
        super(
            Roles.EMPLOYER,
            hrManager,
            cryptographyService,
            shutdownToken,
            EmployerStatesEnum.INITIAL,
            EmployerStatesEnum.TIMEOUT,
            channelService
        );

        channelIdsHashes
            .map((channelIdHash) => {
                if (typeof channelIdHash === "string") {
                    return channelIdHash;
                } else {
                    return channelIdHash.toString("hex");
                }
            })
            .forEach((channelIdHash) => {
                this.channelIdsHashes[channelIdHash] = channelIdHash;
            });

        this.setCorrelationId(`CId:${UUID.UUID()}`);

        this.fsm
            .from(EmployerStatesEnum.INITIAL)
            .to(
                EmployerStatesEnum.STARTED,
                EmployerStatesEnum.ERROR,
                EmployerStatesEnum.TIMEOUT
            );

        this.fsm
            .from(EmployerStatesEnum.STARTED)
            .to(
                EmployerStatesEnum.RECEIVED_JOB_APPLICATION,
                EmployerStatesEnum.ERROR,
                EmployerStatesEnum.TIMEOUT
            );

        this.fsm
            .from(EmployerStatesEnum.RECEIVED_JOB_APPLICATION)
            .to(
                EmployerStatesEnum.SENT_JOB_OFFER,
                EmployerStatesEnum.ERROR,
                EmployerStatesEnum.TIMEOUT
            );

        this.fsm
            .from(EmployerStatesEnum.SENT_JOB_OFFER)
            .to(
                EmployerStatesEnum.RECEIVED_SIGNED_CONTRACT,
                EmployerStatesEnum.ERROR,
                EmployerStatesEnum.TIMEOUT
            );

        this.fsm
            .from(EmployerStatesEnum.RECEIVED_SIGNED_CONTRACT)
            .to(
                EmployerStatesEnum.WAITING_FOR_RESULTS,
                EmployerStatesEnum.ERROR,
                EmployerStatesEnum.TIMEOUT
            );

        this.fsm
            .from(EmployerStatesEnum.WAITING_FOR_RESULTS)
            .to(
                EmployerStatesEnum.RECEIVED_RESULTS,
                EmployerStatesEnum.REQUESTED_CANCELLATION,
                EmployerStatesEnum.ERROR,
                EmployerStatesEnum.TIMEOUT
            );

        this.fsm
            .from(EmployerStatesEnum.RECEIVED_RESULTS)
            .to(EmployerStatesEnum.COMPLETED);

        this.fsm
            .from(EmployerStatesEnum.COMPLETED)
            .to(EmployerStatesEnum.TERMINATED);

        this.fsm
            .from(EmployerStatesEnum.REQUESTED_CANCELLATION)
            .to(
                EmployerStatesEnum.RECEIVED_CANCELLATION_CONFIRMATION,
                EmployerStatesEnum.RECEIVED_RESULTS,
                EmployerStatesEnum.ERROR,
                EmployerStatesEnum.TIMEOUT
            );

        this.fsm.from(EmployerStatesEnum.TIMEOUT).to(EmployerStatesEnum.ERROR);

        this.fsm
            .from(EmployerStatesEnum.ERROR)
            .to(EmployerStatesEnum.COMPLETED);

        this.fsm.onEnter(EmployerStatesEnum.STARTED, this.starting.bind(this));

        this.fsm.onEnter(
            EmployerStatesEnum.RECEIVED_JOB_APPLICATION,
            this.sendJobOffer.bind(this)
        );

        this.fsm.onEnter(
            EmployerStatesEnum.SENT_JOB_OFFER,
            this.waitForSignedContract.bind(this)
        );
        this.fsm.onEnter(
            EmployerStatesEnum.RECEIVED_SIGNED_CONTRACT,
            this.startWaitingForResults.bind(this)
        );

        this.fsm.onEnter(
            EmployerStatesEnum.WAITING_FOR_RESULTS,
            this.waitForResults.bind(this)
        );
        this.fsm.onEnter(
            EmployerStatesEnum.RECEIVED_RESULTS,
            this.reportResults.bind(this)
        );

        this.fsm.onEnter(
            EmployerStatesEnum.TIMEOUT,
            this.reportTimeout.bind(this)
        );
        this.fsm.onEnter(EmployerStatesEnum.ERROR, this.reportError.bind(this));

        this.fsm.onEnter(EmployerStatesEnum.COMPLETED, this.cleanup.bind(this));
        this.fsm.onEnter(
            EmployerStatesEnum.TERMINATED,
            this.terminated.bind(this)
        );
    }

    public async start(): Promise<void> {
        await this.fsm.go(EmployerStatesEnum.STARTED);
    }

    public onMessage(message: Message): boolean {
        if (this.actingPeerId && this.actingPeerId != message.originNodeId) {
            return false;
        }

        if (message.correlationId !== this.correlationId) {
            return false;
        }

        this.log(
            `correlationId: ${this.correlationId}, Handling ${message.tag}`
        );

        switch (message.tag) {
            case MessageTagsEnum.JOB_APPLICATION:
                this.actingPeerId = message.originNodeId;
                this.setImmediate(async () => {
                    const decryptedMessage = await MessageCryptoHelper.decryptMessage(
                        this.cryptographyService,
                        message
                    );
                    this.scheduleTransitionTo(
                        "onMessage",
                        EmployerStatesEnum.RECEIVED_JOB_APPLICATION,
                        decryptedMessage
                    );
                });
                return true;

            case MessageTagsEnum.SIGNED_CONTRACT:
                this.setImmediate(async () => {
                    this.actingPeerId = message.originNodeId;
                    const decryptedMessage = await MessageCryptoHelper.decryptMessage(
                        this.cryptographyService,
                        message
                    );
                    this.scheduleTransitionTo(
                        "onMessage",
                        EmployerStatesEnum.RECEIVED_SIGNED_CONTRACT,
                        decryptedMessage
                    );
                });
                return true;

            case MessageTagsEnum.RESULTS_READY:
                this.setImmediate(async () => {
                    const decryptedMessage = await MessageCryptoHelper.decryptMessage(
                        this.cryptographyService,
                        message
                    );
                    this.scheduleTransitionTo(
                        "onMessage",
                        EmployerStatesEnum.RECEIVED_RESULTS,
                        decryptedMessage
                    );
                });
                return true;

            case MessageTagsEnum.ERROR:
                this.setImmediate(async () => {
                    const decryptedMessage = await MessageCryptoHelper.decryptMessage(
                        this.cryptographyService,
                        message
                    );
                    this.scheduleTransitionTo(
                        "onMessage",
                        EmployerStatesEnum.ERROR,
                        decryptedMessage
                    );
                });
                return true;

            case MessageTagsEnum.TIMEOUT:
                this.setImmediate(async () => {
                    const decryptedMessage = await MessageCryptoHelper.decryptMessage(
                        this.cryptographyService,
                        message
                    );
                    this.scheduleTransitionTo(
                        "onMessage",
                        EmployerStatesEnum.TIMEOUT,
                        decryptedMessage
                    );
                });
                return true;

            default:
                return false;
        }
    }

    public get elapsedMs(): number {
        return this.elapsedMs_;
    }

    private async starting(state: any): Promise<void> {
        this.monitorTimeout(
            TimeoutsConfig.waitingForJobApplicationTimeoutMs,
            `${this.role} | Starting Node in ${TimeoutsConfig.waitingForJobApplicationTimeoutMs} ms"`
        );

        this.onMessageEvent = (data, from) => {
            this.setImmediate(() => {
                if (data.buffer && typeof data.buffer === "string") {
                    const message = JSON.parse(data.buffer);
                    const originNodeId = from.toString("hex");
                    message.originNodeId = originNodeId;
                    this.log(
                        `received message from peer ${originNodeId}`,
                        message
                    );
                    this.onMessage(message);
                }
            });
        };

        this.channelService.myEmployerPeer.on(
            PeerRelayEventKinds.MESSAGE,
            this.onMessageEvent
        );

        this.setImmediate(() => {
            Object.keys(this.channelIdsHashes).forEach((channelIdHash) => {
                this.channelService.lookup(channelIdHash).then((foundPeers) => {
                    this.shuffle(foundPeers);

                    if (foundPeers && foundPeers.length > 0) {
                        foundPeers.forEach((peer) => {
                            const volunteerPeerId = this.channelService.strToBuf(
                                peer.host
                            );

                            const taskFunk = async () => {
                                this.channelService.myEmployerPeer.connect(
                                    volunteerPeerId
                                );

                                const myPublicKey = await this.cryptographyService.myPublicKey();

                                if (this.task) {
                                    this.sendMessage(volunteerPeerId, <Message>{
                                        correlationId: this.correlationId,
                                        tag: MessageTagsEnum.JOB_ADVERTIZING,
                                        payload: <JobAdvertizingPayload>{
                                            publicKey: myPublicKey,
                                            requiredCapabilities: this.task
                                                .requiredCapabilities,
                                        },
                                    });
                                }
                            };

                            if (
                                this.channelService.myVolunteerPeerId ===
                                peer.host
                            ) {
                                // if no one was found, run the task on own volunteers in 5 seconds.
                                // the timeout is important because its own volunteers take tasks much faster than remote volunteers
                                setTimeout(taskFunk, 5000);
                            } else {
                                this.setImmediate(taskFunk);
                            }
                        });

                        this.log(
                            `has sent ${MessageTagsEnum.JOB_ADVERTIZING} to found peers: `,
                            foundPeers
                        );
                    } else {
                        this.warn(`Volunteers not found.`);
                    }
                });
            });
        });
    }

    private shuffle(array: PeerInfo[]) {
        array.sort(() => Math.random() - 0.5);
        for (let i = 0; i < array.length - 1; i++) {
            if (array[i].host === this.channelService.myVolunteerPeerId) {
                let tmp = array[i];
                array[i] = array[array.length - 1];
                array[array.length - 1] = tmp;
                break;
            }
        }
    }

    private invokeStopLookingForPeers(): void {
        this.stopLookingForPeers.forEach((stopper) => {
            stopper();
        });
        this.stopLookingForPeers = [];
    }

    private async sendJobOffer(state: any, message: Message): Promise<void> {
        const payload = <JobApplicationPayload>message.payload;
        await this.importTheirPublicKey(payload.publicKey);

        this.invokeStopLookingForPeers();

        this.monitorTimeout(
            TimeoutsConfig.messagingTimeoutMs,
            `${this.role} | Send job offer ${TimeoutsConfig.messagingTimeoutMs} ms`
        );
        const messageToSend = <Message>{
            correlationId: this.correlationId,
            tag: MessageTagsEnum.JOB_OFFER,
            payload: <JobOfferPayload>{
                task: this.task,
            },
        };
        await this.sendMessage(
            this.actingPeerId,
            await MessageCryptoHelper.encryptMessage(
                this.cryptographyService,
                this.theirPublicKey,
                messageToSend
            )
        );
        this.scheduleTransitionTo(
            "sendJobOffer",
            EmployerStatesEnum.SENT_JOB_OFFER
        );
    }

    private async waitForSignedContract(state: any): Promise<void> {
        this.monitorTimeout(
            TimeoutsConfig.messagingTimeoutMs,
            `${this.role} | Wait for signed contract in ${TimeoutsConfig.messagingTimeoutMs} ms`
        );
    }

    private async startWaitingForResults(state: any): Promise<void> {
        this.monitorTimeout(
            TimeoutsConfig.transitionsTimeoutMs,
            `${this.role} | Start waiting for results in ${TimeoutsConfig.transitionsTimeoutMs} ms`
        );
        this.scheduleTransitionTo(
            "startWaitingForResults",
            EmployerStatesEnum.WAITING_FOR_RESULTS
        );
    }

    private async waitForResults(state: any): Promise<void> {
        this.monitorTimeout(
            this.task.timeout,
            `${this.role} | Wait for results in ${this.task.timeout} ms`
        );
    }

    private async reportResults(state: any, message: Message): Promise<void> {
        const payload = <ResultsPayload>message.payload;
        this.elapsedMs_ = payload.elapsedMs | 0 || 0;

        const currentUser = this.authenticationService.currentUserValue;

        // if (currentUser) {
        //     currentUser.balance = this.computationTimeRepository.decrease(
        //         currentUser.balance,
        //         this.elapsedMs
        //     );
            
        //     await this.authenticationService.update(currentUser);
        // }

        this.resultsFuture.resolve(payload.results);
        this.scheduleTransitionToNoTimeout(
            "reportResults",
            EmployerStatesEnum.COMPLETED
        );
    }

    private async reportTimeout(state: any, op: string): Promise<void> {
        this.scheduleTransitionToNoTimeout(
            "reportTimeout",
            EmployerStatesEnum.ERROR,
            <Message>{
                tag: MessageTagsEnum.ERROR,
                correlationId: this.correlationId,
                originNodeId: this.actingPeerId,
                payload: new TimeoutError(
                    `Timeout happened in "${op || "unknown"}"`
                ),
            }
        );
    }

    private async reportError(state: any, message: Message): Promise<void> {
        const payload = <ErrorPayload>message.payload;
        this.elapsedMs_ = payload.elapsedMs | 0 || 0;
        this.resultsFuture.reject(payload.error);
        this.scheduleTransitionToNoTimeout(
            "reportError",
            EmployerStatesEnum.COMPLETED
        );
    }

    public async cleanup(state: any): Promise<void> {
        try {
            this.invokeStopLookingForPeers();
        } catch (e) {
            console.error(e);
        }

        try {
            this.task = null;
            this.resultsFuture = null;
            this.actingPeerId = null;
            this.setCorrelationId(null);
        } catch (e) {
            console.error(e);
        }

        try {
            (this.channelService.myEmployerPeer as any).removeListener(
                PeerRelayEventKinds.MESSAGE,
                this.onMessageEvent
            );
        } catch (e) {
            console.error(`ChannelService.myPeer.removeListener('message')`, e);
        }

        this.scheduleTransitionToNoTimeout(
            "cleanup",
            EmployerStatesEnum.TERMINATED
        );
    }

    private async terminated(state: any): Promise<void> {
        console.log(`${this.role} [${this.correlationId}] | Terminated`);
    }
}
