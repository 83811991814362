import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'demos-page',
    templateUrl: './demos-page.component.html',
    styleUrls: ['./demos-page.component.scss'],
    host: {'class': 'page-component'},
})
export class DemosPageComponent implements OnInit, OnDestroy {
    pageId: string;
    private sub: any;

    demos = [
        {
            name: 'prime-numbers',
            title: 'Prime Numbers',
            description: 'Simple demo with two modes: single node and distributed'
        },
        {
            name: 'video-chat',
            title: 'Video chat',
            description: 'Decentralized video chat'
        },
        {
            name: 'mandelbrot',
            title: 'Mandelbrot Fractals',
            description: 'Simple demo with two modes: single node and distributed'
        },
        {
            name: 'decentralized-db',
            title: 'Decentralized DB',
            description: 'Decentralized alternatives to Reddit, YouTube, Wikipedia, etc'
        },
        {
            name: 'web-torrent',
            title: 'Decentralized File Storage',
            description: 'Decentralized File Storage'
        },
        {
            name: 'ai-video',
            title: 'AI Video',
            description: 'AI Video'
        }
    ];

    constructor(
        private route: ActivatedRoute
    ) {

    }

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.pageId = params['pageId'];
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }
}
