import { Base64 } from 'js-base64';
import { TextUtils } from './text-utils';
import { catchAndLog } from './catch-and-log';

export class InternalAsymmetricCryptoApi {
    private static readonly ALGORITHM_NAME = "RSA-OAEP";
    private static readonly MODULUS_LENGTH = 4096;
    private static readonly PUBLIC_EXPONENT = new Uint8Array([1, 0, 1]);
    private static readonly HASH_NAME = "SHA-512";

    private static readonly KEY_PARAMETERS = {
        name: InternalAsymmetricCryptoApi.ALGORITHM_NAME,
        modulusLength: InternalAsymmetricCryptoApi.MODULUS_LENGTH,
        publicExponent: InternalAsymmetricCryptoApi.PUBLIC_EXPONENT,
        hash: InternalAsymmetricCryptoApi.HASH_NAME,
    };

    private static readonly EXPORTED_KEY_FORMAT = "spki";
    private static readonly ENCRYPT_KEY_USAGES = ["encrypt", "wrapKey"];
    private static readonly DECRYPT_KEY_USAGES = ["decrypt", "unwrapKey"];
    private static readonly GENERATE_KEY_USAGES = [...InternalAsymmetricCryptoApi.ENCRYPT_KEY_USAGES, ...InternalAsymmetricCryptoApi.DECRYPT_KEY_USAGES];

    public static async generateKeyPair(): Promise<KeysPair> {
        return await catchAndLog("InternalAsymmetricCryptoApi.generateKeyPair", async () => {
            const keys = await crypto.subtle.generateKey(
                InternalAsymmetricCryptoApi.KEY_PARAMETERS,
                true,
                InternalAsymmetricCryptoApi.GENERATE_KEY_USAGES
            );
            const result = <KeysPair>{
                privateKey: keys.privateKey,
                publicKey: keys.publicKey
            };
            return result;
        });
    }

    public static async exportKey(nativeKey: CryptoKey): Promise<string> {
        return await catchAndLog("InternalAsymmetricCryptoApi.exportKey", async () => {
            const jsonNativeKey = JSON.stringify(nativeKey);
            const exportedKey = await crypto.subtle.exportKey(
                InternalAsymmetricCryptoApi.EXPORTED_KEY_FORMAT,
                nativeKey
            );
            const utf16key = TextUtils.arrayBuffer2string(exportedKey as ArrayBuffer);
            const base64key = Base64.encode(utf16key);
            return base64key;
        });
    }

    public static async importKey(base64Key: string): Promise<CryptoKey> {
        return await catchAndLog("InternalAsymmetricCryptoApi.importKey", async () => {
            const utf16key = Base64.decode(base64Key) as string;
            const abKey = TextUtils.string2arrayBuffer(utf16key);
            const importedKey = await crypto.subtle.importKey(
                InternalAsymmetricCryptoApi.EXPORTED_KEY_FORMAT,
                abKey,
                <RsaHashedImportParams>{
                    hash: <AlgorithmIdentifier>{
                        name: InternalAsymmetricCryptoApi.HASH_NAME
                    },
                    name: InternalAsymmetricCryptoApi.ALGORITHM_NAME
                },
                false,
                InternalAsymmetricCryptoApi.ENCRYPT_KEY_USAGES
            );
            return importedKey;
        });
    }

    public static async encrypt(
        publicKey: CryptoKey,
        inputData: Int8Array | Int16Array | Int32Array | Uint8Array | Uint16Array | Uint32Array | Uint8ClampedArray | Float32Array | Float64Array | DataView | ArrayBuffer
    ): Promise<ArrayBuffer> {
        return await catchAndLog("InternalAsymmetricCryptoApi.encrypt", async () => {
            const encrypted = await crypto.subtle.encrypt(InternalAsymmetricCryptoApi.ALGORITHM_NAME, publicKey, inputData);
            return encrypted;
        });
    }

    public static async decrypt(
        privateKey: CryptoKey,
        data: Int8Array | Int16Array | Int32Array | Uint8Array | Uint16Array | Uint32Array | Uint8ClampedArray | Float32Array | Float64Array | DataView | ArrayBuffer
    ): Promise<any> {
        return await catchAndLog("InternalAsymmetricCryptoApi.decrypt", async () => {
            const decrypted = await crypto.subtle.decrypt(InternalAsymmetricCryptoApi.ALGORITHM_NAME, privateKey, data);
            return decrypted;
        });
    }
}

export interface KeysPair {
    privateKey: CryptoKey;
    publicKey: CryptoKey;
}
