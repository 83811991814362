import { IDisposable } from '../../misc/interfaces/idisposable';
import { IO2Cloud } from '../interfaces/io2-cloud';
import { TaskInfo } from '../interfaces/task-info';
import { IHRManager } from '../interfaces/ihr-manager';
import { HRManager } from './hr-manager';
import { IJobMarketInfo } from '../interfaces/ijob-market-info';
import { IHardwareInfo } from '../../misc/interfaces/ihardware-info';
import { ICodeExecutorFactory } from '../interfaces/icode-executor-factory';
import { UUID } from 'angular2-uuid';
import { ResolvablePromise } from '../../core/resolvable-promise';
import { EmployerFsmFactory } from './fsm/employer-fsm-factory';
import { VolunteerFsmFactory } from './fsm/volunteer-fsm-factory';
import { TimeoutError } from '../timeout-error';
import { RealCryptographyService } from '../../core/real-cryptography.service';
import { ICryptography } from '../interfaces/icryptography';
import { IChannelService } from '../interfaces/ichannel-service';
import { Injectable } from '@angular/core';
import { IComputationTimeRepository } from '../interfaces/Icomputation-time-repository';
import { AuthenticationService } from 'src/app/services';

@Injectable({
    providedIn: 'root',
})
export class O2Cloud implements IO2Cloud {
    public static readonly DEFAULT_INITIAL_TASK_RETRY_DELAY = 200;// milliseconds
    private static readonly DEFAULT_TASK_RETRY_COUNT = 10;

    public readonly nodeId: string = `CloudNode:${(UUID.UUID())}`;
    private readonly hrManager: IHRManager;
    private runningTasks_: number = 0;

    public constructor(
        private readonly hardwareInfo: IHardwareInfo,
        private readonly jobMarketInfo: IJobMarketInfo,
        private readonly codeExecutorFactory: ICodeExecutorFactory,
        private readonly employerFsmFactory: EmployerFsmFactory,
        private readonly volunteerFsmFactory: VolunteerFsmFactory,
        private readonly cryptographyService: ICryptography = new RealCryptographyService(),
        private readonly channelService: IChannelService,
        private readonly computationTimeRepository: IComputationTimeRepository,
        private readonly authenticationService: AuthenticationService
    ) {

        this.hrManager = new HRManager(
            this,
            this.cryptographyService,
            this.employerFsmFactory, 
            this.volunteerFsmFactory,
            this.hardwareInfo,
            this.codeExecutorFactory,
            this.channelService,
            this.computationTimeRepository,
            this.authenticationService
        );
    }

    public dispose(): Promise<void> {
        return Promise.resolve();
    }

    public async start(channelIds: any[]): Promise<IDisposable> {
        let jobBoards = await this.jobMarketInfo.DefaultJobBoardIds;
        jobBoards = jobBoards.concat(channelIds);
  
        const hrStopper = await this.hrManager.startVolunteers(jobBoards);
        return hrStopper;
    }

    public get elapsedMs(): number {
        return this.hrManager.elapsedMs;
    }

    public get totalCores(): number {
        return this.hardwareInfo.numberOfCpuCores;
    }
    public set totalCores(value: number) {
        this.hardwareInfo.numberOfCpuCores = value;
    }

    public get usedCores(): number {
        return this.hrManager.usedCores;
    }

    public get availableCores(): number {
        return Math.max(this.totalCores - this.usedCores, 0);
    }

    public get isFullyLoaded(): boolean {
        return this.availableCores <= 0;
    }

    public get runningRemoteTasks(): number {
        return this.runningTasks - this.usedCores;
    }

    public get runningTasks(): number {
        return this.runningTasks_;
    }

    private incRunningTasks(): void {
        this.runningTasks_++;
    }

    private decRunningTasks(): void {
        this.runningTasks_--;
    }

    public async executeTask<R = void>(
        task: TaskInfo,
        jobPortalId?: string
    ): Promise<R> {
        if (!task) {
            throw new Error('task is null');
        }

        let lastError = new Error("Unknown error, should not be thrown!");

        const retryCount = task.retryCount || O2Cloud.DEFAULT_TASK_RETRY_COUNT;
        const initialDelayIntervalMs = task.timeout || O2Cloud.DEFAULT_INITIAL_TASK_RETRY_DELAY;
        for (let iTry = 0, delayInterval = initialDelayIntervalMs;
            iTry < retryCount;
            ++iTry, delayInterval *= 2
        ) {
            this.incRunningTasks();
            try {
                const channelIds = jobPortalId ? [jobPortalId] : await this.jobMarketInfo.DefaultJobBoardIds;
                const resultsFuture = this.hrManager.executeTask<R>(task, channelIds);
                const results = await this.completeOrTimeout<R>(resultsFuture, delayInterval);
                return results;
            }
            catch (e) {
                lastError = e;
                if (e instanceof TimeoutError) {
                    console.warn(`Task timed out, restarting if still possible...`);
                    continue;
                }

                throw e;
            }
            finally {
                this.decRunningTasks();
            }
        }

        debugger;
        console.error(lastError);
        throw lastError;
    }

    public async executeAllTasks<R = void>(
        tasks: TaskInfo[],
        jobPortalId?: string
    ): Promise<R[]> {
        if (!tasks) {
            throw new Error('tasks is null');
        }

        const pendingTasks: Promise<R>[] = this.startAll<R>(tasks, jobPortalId);
        const results = await Promise.all(pendingTasks);

        return results;
    }

    public async executeAnyTask<R = void>(
        tasks: TaskInfo[],
        jobPortalId?: string
    ): Promise<R> {
        if (!tasks) {
            throw new Error('tasks is null');
        }

        const pendingTasks: Promise<R>[] = this.startAll<R>(tasks, jobPortalId);
        const results = await Promise.race(pendingTasks);
        return results;
    }

    private startAll<R = void>(
        tasks: TaskInfo[],
        jobPortalId?: string
    ): Promise<R>[] {
        const pendingTasks: Promise<R>[] = [];
        for (let i = 0; i < tasks.length; ++i) {
            const task = tasks[i];
            const pt = this.executeTask<R>(task, jobPortalId);
            pendingTasks.push(pt);
        }
        return pendingTasks;
    }

    private completeOrTimeout<R = void>(resultPromise: Promise<R>, interval: number): Promise<R> {
        const resultsFuture = new ResolvablePromise<R>();

        const h = setTimeout(
            () => {
                clearTimeout(h);
                resultsFuture.reject(new TimeoutError());
            },
            interval
        );

        resultPromise
            .then(r => {
                clearTimeout(h);
                resultsFuture.resolve(r);
            })
            .catch(e => {
                clearTimeout(h);
                resultsFuture.reject(e);
            });

        return resultsFuture.future;
    }
}
