import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MainPageComponent } from './components/main-page/main-page.component';
import { EmployerPageComponent } from './components/employer-page/employer-page.component';
import { DemosPageComponent } from './components/demos-page/demos-page.component';
import { TryPageComponent } from './components/try-page/try-page.component';
import { TeamPageComponent } from './components/team-page/team-page.component';
import { VolunteerPageComponent } from './components/volunteer-page/volunteer-page.component';
import { MandelbrotSetComponent } from './components/mandelbrot-set/mandelbrot-set.component';
import { DemoVideoChatComponent } from './components/demo-video-chat/demo-video-chat.component';

import { LoginPageComponent } from './components/login-page/login-page.component';
import { RegisterPageComponent } from './components/register-page/register-page.component';
import { AuthGuard } from './helpers/auth.guard';

const routes: Routes = [
    { path: '', component: MainPageComponent },
    { path: 'employer', component: EmployerPageComponent },
    { path: 'demos', component: DemosPageComponent },
    { path: 'demos/video-chat', component: DemoVideoChatComponent, canActivate: [AuthGuard] },
    { path: 'demos/:pageId', component: DemosPageComponent },
    { path: 'try', component: TryPageComponent },
    { path: 'team', component: TeamPageComponent },
    { path: 'volunteer', component: VolunteerPageComponent },
    { path: 'mandelbrot', component: MandelbrotSetComponent },
    { path: 'login', component: LoginPageComponent },
    { path: 'register', component: RegisterPageComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
