import { Injectable } from '@angular/core';
import * as Gun from 'gun';
import 'gun/sea';

@Injectable()
export class GunDb {
    readonly gun = GunDb.getGun();

    private static getGun(): any {
        const gun = Gun('https://gunjs.herokuapp.com/gun');
        return gun;
    }
}
