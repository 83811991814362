import { Injectable } from "@angular/core";
import { IHardwareInfo } from "../interfaces/ihardware-info";

@Injectable()
export class HardwareInfo implements IHardwareInfo {
    private static readonly FakeCpuCount = 2;
    private static readonly FakeNavigator = {
        hardwareConcurrency: HardwareInfo.FakeCpuCount
    };

    public get totalStorageSizeBytes(): number {
        return (50 * 1024 * 1024) | 0;
    }

    public get availableStorageSizeBytes(): number {
        return this.totalStorageSizeBytes;
    }

    public get usedStorageSizeBytes(): number {
        return 0;
    }

    public get totalMemorySizeBytes(): number {
        return (250 * 1024 * 1024) | 0;
    }

    public get availableMemorySizeBytes(): number {
        return this.totalMemorySizeBytes;
    }

    public get usedMemorySizeBytes(): number {
        return 0;
    }

    public get isGpuAvailable(): boolean {
        return false;
    }

    private numberOfCpuCores_: number = null;

    public get hardwareNumberOfCpuCores(): number {
        const cpuCoresCount = (navigator || HardwareInfo.FakeNavigator).hardwareConcurrency || HardwareInfo.FakeCpuCount;
        return cpuCoresCount;
    }

    public get numberOfCpuCores(): number {
        const cpuCoresCount = this.numberOfCpuCores_ || this.hardwareNumberOfCpuCores;
        return cpuCoresCount;
    }

    public set numberOfCpuCores(value: number) {
        if (value == null) {
            this.numberOfCpuCores_ = null;
            return;
        }

        this.numberOfCpuCores_ = Math.max(1, Math.min(this.hardwareNumberOfCpuCores, value));
    }

    public get isMobile(): boolean {
        return true;
    }
}
