import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { WebAssemblyUrlCodeExecutor } from '../p2p-cloud/flow/impls/executors/webasm/webassemblyurl-code-executor';
import { TaskInfo, CodeTypesEnum } from '../p2p-cloud/flow/interfaces/task-info';
import { IO2Cloud } from '../p2p-cloud/flow/interfaces/io2-cloud';

@Component({
  selector: 'app-mandelbrot-set',
  templateUrl: './mandelbrot-set.component.html',
  styleUrls: ['./mandelbrot-set.component.scss']
})
export class MandelbrotSetComponent implements OnInit, OnDestroy {
  public progressValue: number;
  public isViewFullscreen = false;
  public isShutdownRequested = false;

  @ViewChild("mandelbrotCanvas", {static: true}) canvasWrapper: any;

  public constructor(private readonly cloud: IO2Cloud) {
  }

  ngOnInit() {
    setTimeout(async () => await this.onStart());
  }

  ngOnDestroy() {
    this.isShutdownRequested = true;
  }

  private async onStart() {

    const thisComponent = this;
    const animate = true;

    // Set up the canvas with a 2D rendering context
    const cnv = this.canvasWrapper.nativeElement;
    const ctx = cnv.getContext("2d");
    const bcr = cnv.getBoundingClientRect();
    // Compute the size of the viewport
    let width = bcr.width | 0;
    let height = bcr.height | 0;
    const ratio = window.devicePixelRatio || 1;
    width *= ratio;
    height *= ratio;
    var size = width * height;
    var byteSize = size << 1; // discrete color indices in range [0, 2047] (here: 2b per pixel)
    cnv.width = width;
    cnv.height = height;
    ctx.scale(ratio, ratio);

    var imageData = ctx.createImageData(width, height);
    var argb = new Uint32Array(imageData.data.buffer);

    const limit = 25;

    // Compute the size of and instantiate the module's memory
    const memory = new WebAssembly.Memory({ initial: ((byteSize + 0xffff) & ~0xffff) >>> 16 });
    var mem = new Uint16Array(memory.buffer);
    const task = <TaskInfo>{
      codeType: CodeTypesEnum.WEB_ASSEMBLY_URL,
      codeLocation: "https://allconnectix.visualstudio.com/defaultcollection/algorithms/_apis/git/repositories/algorithms/items?api-version=1.0&scopepath=demo/mandelbrot/optimized.wasm",
      actionToInvoke: "computeLine",
      args: [],
      imports: {
        env: { memory },
        Math
      },
    };
    const codeExecutor = await WebAssemblyUrlCodeExecutor.create(task, this.cloud);

    // Compute a nice set of colors using a gradient
    var colors = (() => {
      var cnv = document.createElement("canvas");
      cnv.width = 2048;
      cnv.height = 1;
      var ctx = cnv.getContext("2d");
      var grd = ctx.createLinearGradient(0, 0, 2048, 0);
      grd.addColorStop(0.00, "#000764");
      grd.addColorStop(0.16, "#2068CB");
      grd.addColorStop(0.42, "#EDFFFF");
      grd.addColorStop(0.6425, "#FFAA00");
      grd.addColorStop(0.8575, "#000200");
      ctx.fillStyle = grd;
      ctx.fillRect(0, 0, 2048, 1);
      cnv.className = "gradient";
      const buffer = ctx.getImageData(0, 0, 2048, 1).data.buffer;
      // setTimeout(() => document.body.appendChild(cnv));
      return new Uint32Array(buffer);
    })();

    // Fetch and instantiate the module
    try {
      var computeLine = (y: number, w: number, h: number, limit: number) => {
        task.args = [y, w, h, limit];
        //this.cloud.executeTask(task, null, true);
        codeExecutor.executeCode(task, this.cloud);
      };
      var updateLine = (y) => {
        var yx = y * width;
        for (let x = 0; x < width; ++x) {
          argb[yx + x] = colors[mem[yx + x]];
        }
      };
      // Compute an initial balanced version of the set.
      // for (let y = 0; y < height; ++y) {
      //   computeLine(y, width, height, limit);
      //   updateLine(y);
      // }
      // Keep rendering the image buffer.
      (function render() {
        if (animate) requestAnimationFrame(render);
        ctx.putImageData(imageData, 0, 0);
      })();
      if (animate) {
        // Let it glow a bit by occasionally shifting the limit...
        var currentLimit = limit;
        var shiftRange = 10;
        (function updateShift() {
          currentLimit = limit + (2 * Math.random() * shiftRange - shiftRange) | 0;
          if (thisComponent.isShutdownRequested) return;
          setTimeout(updateShift, 1000 + (1500 * Math.random()) | 0);
        })();
        // ...while continously recomputing a subset of it.
        var flickerRange = 3;
        (function updateFlicker() {
          for (let i = 0, k = (0.05 * height) | 0; i < k; ++i) {
            let ry = (Math.random() * height) | 0;
            let rl = (2 * Math.random() * flickerRange - flickerRange) | 0;
            computeLine(ry, width, height, currentLimit + rl);
            updateLine(ry);
          }
          if (thisComponent.isShutdownRequested) return;
          setTimeout(updateFlicker, 1000 / 30);
        })();
      }
    }
    catch (e) {
      alert("Failed to load WASM: " + e.message + " (ad blocker, maybe?)");
      console.error(e);
    }
  }

  toggleViewFullscreen() {
    this.isViewFullscreen = !this.isViewFullscreen;
  }
}
