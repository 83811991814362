import { IHRManager } from '../../interfaces/ihr-manager';
import { TaskInfo } from '../../interfaces/task-info';
import { ResolvablePromise } from '../../../core/resolvable-promise';
import { CancellationToken } from '../../../core/cancellation-token';
import { EmployerFsm } from './employer-fsm';
import { ICryptography } from '../../interfaces/icryptography';
import { IChannelService } from '../../interfaces/ichannel-service';
import { IComputationTimeRepository } from '../../interfaces/Icomputation-time-repository';
import { AuthenticationService } from 'src/app/services';

export class EmployerFsmFactory {
    public async start<R = void>(
        hrManager: IHRManager,
        cryptographyService: ICryptography,
        channelIds: string[],
        task: TaskInfo,
        resultsFuture: ResolvablePromise<R>,
        shutdownToken: CancellationToken,
        channelService: IChannelService,
        computationTimeRepository: IComputationTimeRepository,
        authenticationService: AuthenticationService
    ): Promise<EmployerFsm<R>> {
        const fsm = new EmployerFsm<R>(
            hrManager,
            cryptographyService,
            channelIds,
            task,
            resultsFuture,
            shutdownToken,
            channelService,
            computationTimeRepository,
            authenticationService
        );
        await fsm.start();
        return fsm;
    }
}
