import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { IO2Cloud } from '../p2p-cloud/flow/interfaces/io2-cloud';
import { TaskInfo, CodeTypesEnum } from '../p2p-cloud/flow/interfaces/task-info';

@Component({
    selector: 'try-page',
    templateUrl: './try-page.component.html',
    styleUrls: ['./try-page.component.scss'],
    host: { 'class': 'page-component' },
    encapsulation: ViewEncapsulation.None,
})
export class TryPageComponent implements OnInit {
    room: string;
    editorOptions = {
        theme: 'vs-light',
        language: 'javascript',
        automaticLayout: true
    };
    isEditorFullscreen = false;
    isCanvasFullscreen = false;

    isStarted: boolean;
    
    code: string;
    args: string = '[100, 200]';
    result: string;

    constructor(
        public readonly cloud: IO2Cloud) {
    }

    async ngOnInit() {
        //const codeResponse = await fetch('https://allconnectix.visualstudio.com/defaultcollection/algorithms/_apis/git/repositories/algorithms/items?api-version=1.0&scopepath=demo/prime-numbers/prime-numbers-simple.ts');
        this.code = `export function run(args) {
        
    function sum(a, b) {       
        return a + b;
    }

    var absum = sum(args[0], args[1]);    
    return absum;
}        
`  //await codeResponse.text();
    }

    onArgsKey(event: any) {
        this.args = event.target.value;
    }

    async onSubmitBasic() {
        this.isStarted = true;

        this.result = "";

        let jsonArgs = JSON.parse(this.args);

        this.result = await this.cloud.executeTask<string>(
            <TaskInfo>{
                codeType: CodeTypesEnum.SOURCE_TS,
                codeLocation: this.code,
                actionToInvoke: 'run',
                args: [jsonArgs],
                timeout: 10 * 1000,
                retryCount: 5
            },
            null
        );

        this.isStarted = false;
    }

    toggleEditorFullscreen() {
        this.isEditorFullscreen = !this.isEditorFullscreen;
    }

    toggleCanvasFullscreen() {
        this.isCanvasFullscreen = !this.isCanvasFullscreen;
    }
}
