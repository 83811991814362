import { IO2Cloud } from "../flow/interfaces/io2-cloud";
import { CodeTypesEnum, TaskInfo } from "../flow/interfaces/task-info";
import { ElementRef } from "@angular/core";
import { UUID } from "angular2-uuid";

export class RtcConnection {
    private static readonly rtcConnections = new Map<string, RtcConnection>();

    peerConnection: RTCPeerConnection;
    stream: MediaStream;
    configuration = {
        iceServers: [
            {
                urls: [
                    "stun:stun1.l.google.com:19302",
                    "stun:stun2.l.google.com:19302",
                ],
            },
        ],
        iceCandidatePoolSize: 10,
    };
    offerOptions = {
        offerToReceiveAudio: true,
        offerToReceiveVideo: true,
    };

    public answer: () => Promise<boolean>;
    public getVideoElements: () => Promise<ElementRef[]>;

    public constructor(
        private readonly cloud: IO2Cloud,
        private readonly localUserId: string
    ) {
        (<any>window).rtcConnection =
            (<any>window).rtcConnection || RtcConnection;
        RtcConnection.rtcConnections.set(localUserId, this);
    }

    public async call(
        caller: string,
        callee: string
    ): Promise<boolean> {
        const stream = await this.getUserMedia();
        const videoElements = await this.getVideoElements();

        this.peerConnection = this.buildPeerConnection(
            caller,
            callee,
            stream,
            videoElements
        );

        // Create an offer
        const offerSrc = await this.peerConnection.createOffer(
            this.offerOptions
        );
        await this.peerConnection.setLocalDescription(offerSrc);

        const offer = new RTCSessionDescription(offerSrc);

        // Send the offer to a callee
        const answer = await this.cloud.executeTask<RTCSessionDescriptionInit>(
            <TaskInfo>{
                codeType: CodeTypesEnum.SOURCE_COMPILED_TS,
                codeLocation: `function run(a1, a2, a3){ return window.rtcConnection.catchOffer(a1, a2, a3); }`,
                actionToInvoke: "run",
                args: [callee, caller, offer.toJSON()],
                timeout: 10 * 1000,
                retryCount: 2,
            },
            callee
        );

        console.log(`ANSWER: `, answer);

        if (answer) {
            this.peerConnection.setRemoteDescription(
                new RTCSessionDescription(answer)
            );
            return true;
        } else {
            this.peerConnection.close();
            this.peerConnection = null;
            stream.getTracks().forEach((x) => x.stop());
            return false;
        }
    }

    public static async catchOffer(
        callee: string,
        caller: string,
        offer: RTCSessionDescriptionInit
    ): Promise<string> {
        const self = RtcConnection.rtcConnections.get(callee);
        return self.catchOfferInternal(callee, caller, offer);
    }

    public async catchOfferInternal(
        callee: string,
        caller: string,
        offer: RTCSessionDescriptionInit
    ): Promise<string> {
        if (await this.answer()) {
            const stream = await this.getUserMedia();
            const videoElements = await this.getVideoElements();

            this.peerConnection = this.buildPeerConnection(
                callee,
                caller,
                stream,
                videoElements
            );

            // Sets remote offer
            await this.peerConnection.setRemoteDescription(
                new RTCSessionDescription(offer)
            );

            // Creats answer
            const answer = await this.peerConnection.createAnswer(
                this.offerOptions
            );
            await this.peerConnection.setLocalDescription(answer);

            return new RTCSessionDescription(answer).toJSON();
        }

        return null;
    }

    public static async registerIcecandidate(
        localUserId: string,
        remoteUserId: string,
        candidate: RTCIceCandidateInit
    ): Promise<void> {
        const self = RtcConnection.rtcConnections.get(remoteUserId);
        await self.registerIcecandidateInternal(
            localUserId,
            remoteUserId,
            candidate,
            100
        );
    }

    private async registerIcecandidateInternal(
        localUserId: string,
        remoteUserId: string,
        candidate: RTCIceCandidateInit,
        attemtsLeft: number
    ) {
        if (this.peerConnection) {
            await this.peerConnection.addIceCandidate(
                new RTCIceCandidate(candidate)
            );
        } else if (attemtsLeft > 0) {
            setTimeout(async () => {
                await this.registerIcecandidateInternal(
                    localUserId,
                    remoteUserId,
                    candidate,
                    attemtsLeft - 1
                );
            }, 100);
        }
    }

    private buildPeerConnection(
        localUserId: string,
        remoteUserId: string,
        stream: MediaStream,
        videoElements: ElementRef[]
    ): RTCPeerConnection {
        const peerConnection = new RTCPeerConnection(this.configuration);

        peerConnection.addEventListener("icecandidate", async (event) => {
            if (!event.candidate) {
                console.log("Got final candidate!");
                return;
            }
            const candidate = event.candidate.toJSON();
            console.log("Got candidate: ", candidate);
            try {
                await this.cloud.executeTask<any[]>(
                    <TaskInfo>{
                        codeType: CodeTypesEnum.SOURCE_COMPILED_TS,
                        codeLocation: `function run(a1, a2, a3){ window.rtcConnection.registerIcecandidate(a1, a2, a3); }`,
                        actionToInvoke: "run",
                        args: [localUserId, remoteUserId, candidate],
                        timeout: 10 * 1000,
                        retryCount: 2,
                    },
                    remoteUserId
                );
            } catch (e) {
                console.error(e);
            }
        });

        // Gets stream from remote node
        peerConnection.addEventListener("track", async (event) => {
            if (videoElements[1].nativeElement.srcObject !== event.streams[0]) {
                videoElements[1].nativeElement.srcObject = event.streams[0];
            }
        });

        // Sends stream to remote node
        if (stream) {
            stream.getTracks().forEach((track) => {
                peerConnection.addTrack(track, stream);
                videoElements[0].nativeElement.srcObject = new MediaStream(stream.getVideoTracks());
            });
        }

        // register other events
        this.registerPeerConnectionListeners(peerConnection);

        return peerConnection;
    }

    private async getUserMedia(): Promise<MediaStream> {
        try {
            return (
                this.stream ||
                (this.stream = await navigator.mediaDevices.getUserMedia({
                    video: true,
                    audio: true,
                }))
            );
        } catch (e) {
            return null;
        }
    }

    private registerPeerConnectionListeners(
        peerConnection: RTCPeerConnection
    ): void {
        peerConnection.addEventListener("icegatheringstatechange", () => {
            console.log(
                `ICE gathering state changed: ${peerConnection.iceGatheringState}`
            );
        });

        peerConnection.addEventListener("connectionstatechange", () => {
            console.log(
                `Connection state change: ${peerConnection.connectionState}`
            );
        });

        peerConnection.addEventListener("signalingstatechange", () => {
            console.log(
                `Signaling state change: ${peerConnection.signalingState}`
            );
        });

        peerConnection.addEventListener("iceconnectionstatechange ", () => {
            console.log(
                `ICE connection state change: ${peerConnection.iceConnectionState}`
            );
        });
    }
}
