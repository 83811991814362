import { IComputationTimeRepository } from "../interfaces/Icomputation-time-repository";

export class ComputationTimeRepository implements IComputationTimeRepository {
    public increase(balance: number, payload: number): number {
        return balance + this.getUnitsOfWork(payload);
    }

    public decrease(balance: number, payload: number): number {
        return balance - this.getUnitsOfWork(payload);
    }

    private getUnitsOfWork(payload: number): number {
        return Math.ceil(payload / 100);
    }
}
