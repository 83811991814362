import { IFlowFsm } from '../../interfaces/iflow-fsm';
import { IHRManager } from '../../interfaces/ihr-manager';
import { TypeState } from 'typestate';
import { Message } from '../../interfaces/task-info';
import { CancellationToken } from '../../../core/cancellation-token';
import { ICryptography } from '../../interfaces/icryptography';
import { TimeoutTransitionArgs } from './timeout-transition-args';
import { TimeoutsConfig } from './timeouts-config';
import { IChannelService } from '../../interfaces/ichannel-service';
import { Roles } from './roles';
import { PeerRelay } from '../web-torrent-defs/bittorrent-dht/bittorrent-dht-defs';

export abstract class FsmBase<TStates> implements IFlowFsm {
    protected readonly fsm: TypeState.FiniteStateMachine<TStates>;
    private hTimeout: any;

    private correlationId_: string | null = null;
    private theirPublicKey_: CryptoKey | null = null;
    protected actingPeerId: any = null;

    protected constructor(
        protected readonly role: string,
        protected readonly hrManager: IHRManager,
        protected readonly cryptographyService: ICryptography,
        protected readonly shutdownToken: CancellationToken,
        initialState: TStates,
        private readonly timeoutState: TStates,
        protected readonly channelService: IChannelService
    ) {
        this.fsm = new TypeState.FiniteStateMachine<TStates>(initialState, true);
    }

    protected sendMessage(targetNodeId: any, message: Message): void {
        if (targetNodeId) {
            const strMessage = JSON.stringify(message);
            this.getPeer().send(targetNodeId, { buffer: strMessage });
        }
    }

    protected stringOrStringify(data: any): string {
        if (typeof data === "string") {
            return <string>data;
        }
        return JSON.stringify(data);
    }

    public get correlationId(): string {
        return this.correlationId_;
    }

    protected setCorrelationId(value: string) {
        this.correlationId_ = value;
    }

    protected get theirPublicKey(): CryptoKey {
        return this.theirPublicKey_;
    }

    protected async importTheirPublicKey(publicKey: string): Promise<void> {
        if (!publicKey) debugger;
        this.theirPublicKey_ = await this.cryptographyService.importKey(publicKey);
    }

    protected setImmediate(callback: Function, ...args: any[]): void {
        this.stopMonitoringTimeout();
        const h = setTimeout(
            async () => {
                clearTimeout(h);
                await callback(...args);
            },
            0
        );
    }

    protected scheduleTransitionTo(label: string, newState: TStates, arg?: any): void {
        this.stopMonitoringTimeout();
        this.setImmediate(async () => {
            const oldState = this.fsm.currentState;
            if (oldState === newState) return;
            if (!this.fsm.canGo(newState)) return;

            console.log(`${this.role} | ${label} | Scheduling transition "${oldState}" => "${newState}".`);

            if (!this.fsm.canGo(newState)) {
                debugger;
                throw new Error(`${this.role}[${this.correlationId}] | ${label} | Cannot go "${oldState}" => "${newState}".`);
            }

            this.monitorTimeout(TimeoutsConfig.transitionsTimeoutMs, `${this.role} | ${label} | Executing scheduled transition "${oldState}" => "${newState}" in ${TimeoutsConfig.transitionsTimeoutMs} ms`);
            await this.fsm.go(newState, arg);
        });
    }

    protected scheduleTransitionToNoTimeout(label: string, newState: TStates, arg?: any): void {
        this.stopMonitoringTimeout();
        this.setImmediate(async () => {
            const oldState = this.fsm.currentState;
            if (oldState === newState) return;
            if (!this.fsm.canGo(newState)) return;

            console.log(`${this.role} | ${label} | Scheduling transition "${oldState}" => "${newState}".`);

            if (!this.fsm.canGo(newState)) {
                debugger;
                throw new Error(`${this.role}[${this.correlationId}] | ${label} | Cannot go "${oldState}" => "${newState}".`);
            }

            await this.fsm.go(newState, arg);
        });
    }

    protected monitorTimeout(timeoutIntervalMs: number, op: string): void {
        this.monitorTimeoutInternal(
            timeoutIntervalMs,
            <TimeoutTransitionArgs>{
                doReport: true,
                cause: op
            }
        );
    }

    protected monitorTimeoutNoReport(timeoutIntervalMs: number, op: string): void {
        this.monitorTimeoutInternal(
            timeoutIntervalMs,
            <TimeoutTransitionArgs>{
                doReport: false,
                cause: op
            }
        );
    }

    protected monitorTimeoutInternal(timeoutIntervalMs: number, args: TimeoutTransitionArgs): void {
        this.stopMonitoringTimeout();

        if (this.fsm.canGo(this.timeoutState)) {
            this.hTimeout = setTimeout(
                async () => {
                    clearTimeout(this.hTimeout);
                    this.hTimeout = null;

                    if (this.fsm.canGo(this.timeoutState)) {
                        await this.fsm.go(this.timeoutState, args);
                    }
                    else {
                        console.warn(`${this.role} | Cannot go "${this.fsm.currentState}" => "${this.timeoutState}".`);
                    }
                },
                Math.max(timeoutIntervalMs || 30 * 1000, 10 * 1000)
            );
        }
        else {
            console.warn(`${this.role} | Cannot go "${this.fsm.currentState}" => "${this.timeoutState}".`);
        }
    }

    protected stopMonitoringTimeout(): void {
        if (this.hTimeout) {
            clearTimeout(this.hTimeout);
            this.hTimeout = null;
        }
    }

    protected log(action: string, ...args: any[]): void {
        console.log(`[${this.role}: ${this.getPeerId()}] | ${action} | `, ...args);
    }

    protected warn(action: string, ...args: any[]): void {
        console.warn(`[${this.role}: ${this.getPeerId()}] | ${action} | `, ...args);
    }

    private getPeerId(): string {
        switch (this.role) {
            case Roles.EMPLOYER:
                return this.channelService.myEmployerPeerId;

            case Roles.VOLUNTEER:
                return this.channelService.myVolunteerPeerId;
        }
    }

    private getPeer(): PeerRelay {
        switch (this.role) {
            case Roles.EMPLOYER:
                return this.channelService.myEmployerPeer;

            case Roles.VOLUNTEER:
                return this.channelService.myVolunteerPeer;
        }
    }

    public abstract start(): Promise<void>;
    public abstract onMessage(message: Message, from: any): boolean;
    public abstract cleanup(state: any): Promise<void>;
}
