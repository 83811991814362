import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {IO2Cloud} from '../p2p-cloud/flow/interfaces/io2-cloud';

@Component({
    selector: 'volunteer-page',
    templateUrl: './volunteer-page.component.html',
    styleUrls: ['./volunteer-page.component.sass'],
    host: {'class': 'page-component'},
})

export class VolunteerPageComponent implements OnInit {
    progressValue: number;

    constructor(
        private router: ActivatedRoute,
        private formBuilder: FormBuilder,
        private cloud: IO2Cloud
    ) {
    }

    ngOnInit() {
        this.progressValue = 30;
    }

    async run() {
    }
}
