import { isPromiseAlike } from 'q';
import { ResolvablePromise } from './resolvable-promise';

type RT<R = void> = R | Promise<R>;
type RTF<R = void> = () => RT<R>;

export async function catchAndLog<R = void>(label: string, action: RTF<R>): Promise<R> {
    const resultFuture = new ResolvablePromise<R>();
    try {
        const rt = action();

        if (isPromiseAlike(rt)) {
            const r: R = await rt;
            resultFuture.resolve(r);
        }
        else {
            resultFuture.resolve(<R>rt);
        }
    }
    catch (e) {
        const error = `Error while ${label}: ${e}`;
        console.error(error);
        //debugger;
        resultFuture.reject(new Error(error));
    }

    const result = await resultFuture.future;
    return result;
}
