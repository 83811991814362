import { IChannelService } from '../interfaces/ichannel-service';
import { PeerRelaySocket, IPRSocket, PeerRelayEventKinds, PeerInfo, PeerRelay } from './web-torrent-defs/bittorrent-dht/bittorrent-dht-defs';
import * as signalR from '@microsoft/signalr';
import { ResolvablePromise } from '../../core/resolvable-promise';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ChannelService extends IChannelService {

    private static readonly DefaultPeerRelayBootstrapServers: string[] = [
        'wss://bootstrap-dht-server.azurewebsites.net/'
    ];

    private static readonly DefaultSignalBootstrapServers: string = 'https://skynetus-signal.azurewebsites.net/signal';

    private hubConnection: signalR.HubConnection;
    private isConnected: ResolvablePromise<boolean>;

    private volunteerPrSocket: PeerRelaySocket;
    private employerPrSocket: PeerRelaySocket;

    public get myVolunteerPeerId(): string { return PRSocket.bufToStr(this.volunteerPrSocket.peer.id); }
    public get myVolunteerPeer(): PeerRelay { return this.volunteerPrSocket.peer; }

    public get myEmployerPeerId(): string { return PRSocket.bufToStr(this.employerPrSocket.peer.id); }
    public get myEmployerPeer(): PeerRelay { return this.employerPrSocket.peer; }

    public constructor() {
        super();

        // --- PeerRelay ---
        this.volunteerPrSocket = PRSocket.start({ bootstrap: ChannelService.DefaultPeerRelayBootstrapServers });

        this.volunteerPrSocket.peer.on(PeerRelayEventKinds.PEER, function (id) {
            let peerId = PRSocket.bufToStr(id);
            console.log(`My VOLUNTEER connected to peer: `, peerId);
        });

        console.log(`My VOLUNTEER peer id: `, this.myVolunteerPeerId);

        this.employerPrSocket = PRSocket.start({ bootstrap: ChannelService.DefaultPeerRelayBootstrapServers });

        this.employerPrSocket.peer.on(PeerRelayEventKinds.PEER, function (id) {
            let peerId = PRSocket.bufToStr(id);
            console.log(`My EMPLOYER connected to peer: `, peerId);
        });

        console.log(`My EMPLOYER peer id: `, this.myEmployerPeerId);

        // --- SignalR ---
        this.hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(ChannelService.DefaultSignalBootstrapServers)
            .withAutomaticReconnect()
            .configureLogging(signalR.LogLevel.Debug)
            .build();

        this.hubConnection
            .start()
            .then(() => {
                console.log('Connection started');
            })
            .catch(err => console.log('Error while starting connection: ' + err));

        this.hubConnection
            .on('Connected', () => {
                this.isConnected.resolve(true);
            });
    }

    public async publishMe(hash: string): Promise<void> {
        await this.isConnected;
        this.hubConnection.invoke("Put", hash, <PeerInfo>{ host: this.myVolunteerPeerId });
    }

    public async lookup(hash: string): Promise<PeerInfo[]> {
        this.hubConnection.invoke("Lookup", hash);

        const foundPeersPromise = new ResolvablePromise<PeerInfo[]>();

        const handler = (volunteersChannelId, foundPeers) => {
            if (hash === volunteersChannelId) {
                foundPeersPromise.resolve(foundPeers);
            }
        };

        this.hubConnection
            .on("Lookup", handler);

        setTimeout(() => {
            this.hubConnection.off("Lookup", handler);
            foundPeersPromise.reject('Lookup timeout');
        }, 10000);

        const foundPeers = await foundPeersPromise.future;

        this.hubConnection.off("Lookup", handler);

        return foundPeers;
    }

    bufToStr(buf: Uint8Array): string {
        return PRSocket.bufToStr(buf);
    }

    strToBuf(str: string): Uint8Array {
        return PRSocket.strToBuf(str);
    }
}

declare const PRSocket: IPRSocket;