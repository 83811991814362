import { ICodeExecutor } from '../../../interfaces/icode-executor';
import { IO2Cloud } from '../../../interfaces/io2-cloud';
import { TaskInfo, CodeTypesEnum } from '../../../interfaces/task-info';
import { ICodeExecutorFactory } from '../../../interfaces/icode-executor-factory';
import { GcMap } from '../../gc-map';

export class TypescriptUrlCodeExecutor implements ICodeExecutor {
    public static readonly DEFAULT_TTL = 30 * 1000;//30 seconds
    private static readonly downloadedTsCache = new GcMap<string, string>("TypescriptUrlCodeExecutor.downloadedTsCache", TypescriptUrlCodeExecutor.DEFAULT_TTL);

    public constructor(private readonly codeExecutorFactory: ICodeExecutorFactory) {
    }

    private async getCodeExecutor(task: TaskInfo, cloud: IO2Cloud) {
        const impl = await this.codeExecutorFactory.getExecutor(task, cloud);
        return impl;
    }

    public async executeCode(task: TaskInfo, cloud: IO2Cloud): Promise<any> {
        let code = TypescriptUrlCodeExecutor.downloadedTsCache.get(task.codeLocation as string);
        if (!code) {
            code = await (await fetch(task.codeLocation as string)).text();
            console.debug(code);
            TypescriptUrlCodeExecutor.downloadedTsCache.set(task.codeLocation as string, code);
        }

        const taskToExecute = Object.assign(<TaskInfo>{}, task);
        taskToExecute.codeType = CodeTypesEnum.SOURCE_TS;
        taskToExecute.codeLocation = code;
        const impl = await this.getCodeExecutor(taskToExecute, cloud);
        const results = await impl.executeCode(taskToExecute, cloud);
        return results;
    }

    public dispose(): Promise<void> {
        return Promise.resolve();
    }
}
