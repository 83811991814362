import { ICodeExecutor } from '../../../interfaces/icode-executor';
import { IO2Cloud } from '../../../interfaces/io2-cloud';
import { TaskInfo } from '../../../interfaces/task-info';
import { WebAssemblyUrlCodeExecutor } from './webassemblyurl-code-executor';

class CacheEntry {
    public static readonly DEFAULT_TTL = 30 * 1000;//30 seconds
    url: string;
    codeExecutor: ICodeExecutor;
    timeToDie: number = Date.now() + CacheEntry.DEFAULT_TTL;
    timeoutHandle: any;
}

export class CachedWebAssemblyUrlCodeExecutor implements ICodeExecutor {

    private static readonly cachedEntries = new Map<string, CacheEntry>();

    private async getCacheEntry(task: TaskInfo, cloud: IO2Cloud): Promise<CacheEntry> {
        let entry = CachedWebAssemblyUrlCodeExecutor.cachedEntries.get(task.codeLocation as string);
        if (!entry) {
            const url = task.codeLocation as string;
            entry = <CacheEntry>{
                url: url,
                codeExecutor: await WebAssemblyUrlCodeExecutor.create(task, cloud),
                timeToDie: Date.now() + CacheEntry.DEFAULT_TTL,
                timeoutHandle: setInterval(() => {
                    if (Date.now() >= entry.timeToDie) {
                        clearInterval(entry.timeoutHandle);
                        CachedWebAssemblyUrlCodeExecutor.cachedEntries.delete(url)
                    }
                })
            };
        }
        return entry;
    }

    public async executeCode(task: TaskInfo, cloud: IO2Cloud): Promise<any> {
        debugger;
        try {
            const cacheEntry = await this.getCacheEntry(task, cloud);
            const results = cacheEntry.codeExecutor.executeCode(task, cloud);
            return results;
        }
        catch (e) {
            debugger;
            console.error(e);
            throw e;
        }
    }

    public dispose(): Promise<void> {
        return Promise.resolve();
    }
}
