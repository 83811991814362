import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { FileUploader } from 'ng2-file-upload';

@Component({
    selector: 'demo-web-torrent',
    templateUrl: './demo-web-torrent.component.html',
    host: {'class': 'demo-component'},
})

export class WebTorrentPageComponent implements OnInit {
    magnetURI: string = "";
    uploader: FileUploader;
    uploadedFiles = [];
    fileProgress = '';

    constructor(
        public snackBar: MatSnackBar,
    ) {}

    ngOnInit(): void {
        this.uploader = new FileUploader({});
    }

    onFileSelected(fileList) {
        WebDHT.upload(fileList[0], magnetURI => {
            this.uploadedFiles.push(btoa(magnetURI));
        });
    }

    download() {
        const decodedMagnetURL = atob(this.magnetURI);
        const torrent = WebDHT.get(decodedMagnetURL);

        if (torrent) {
            this.onTorrent(torrent);
        } else {
            WebDHT.download(decodedMagnetURL, (torrent) => {
                this.onTorrentProgress(torrent);
            });
        }
    }

    onTorrent(torrent) {
        // Render all files into to the page
        torrent.files.forEach(file => {
            file.appendTo('.downloaded-files');
        });
    }

    onTorrentProgress(torrent) {
        // Print out progress every 1 second
        const interval = setInterval(() => {
            this.fileProgress = `Progress: ${(torrent.progress * 100).toFixed(1)}%`;
        }, 1000);

        torrent.on('done', () => {
            this.fileProgress = 'Progress: 100%';
            clearInterval(interval);
            this.onTorrent(torrent);
        });
    }

    //Taken from - https://stackoverflow.com/questions/49102724/angular-5-copy-to-clipboard
    copyInputMessage(inputElement) {
        inputElement.select();
        inputElement.setSelectionRange(0,9999); //iOS
        document.execCommand('copy');
        inputElement.setSelectionRange(0, 0);
        this.snackBar.open('Copied to clipboard', 'OK', {
            duration: 2000,
        });
    }
}

declare const WebDHT: IDHTFactory;

interface IDHTFactory {
    start(opts);
    upload(file, receiveMagnetURI);
    download(magnetURI, onTorrent);
    get(magnetURI);
}
