import { IDisposable } from '../../misc/interfaces/idisposable';
import { TaskInfo } from './task-info';

export abstract class IHRManager extends IDisposable {
    public abstract get nodeId(): string;

    public abstract get elapsedMs(): number;
    public abstract get usedCores(): number;

    public abstract startVolunteers(channelIds: any[]): Promise<IDisposable>;

    public abstract executeTask<R = void>(task: TaskInfo, jobPortalIds: string[]): Promise<R>;
}
