import { ICodeExecutor } from '../../../interfaces/icode-executor';
import { IO2Cloud } from '../../../interfaces/io2-cloud';
import { TaskInfo, CodeTypesEnum } from '../../../interfaces/task-info';
import * as ts from 'typescript';
import { ICodeExecutorFactory } from '../../../interfaces/icode-executor-factory';
import { GcMap } from '../../gc-map';

export class TypescriptCodeExecutor implements ICodeExecutor {
    public static readonly DEFAULT_TTL = 30 * 1000; // 30 seconds
    private static readonly compiledCodes = new GcMap<string, string>
        ('TypescriptCodeExecutor.compiledNodes', TypescriptCodeExecutor.DEFAULT_TTL);

    public constructor(private readonly codeExecutorFactory: ICodeExecutorFactory) {
    }

    private async getCodeExecutor(task: TaskInfo, cloud: IO2Cloud) {
        const impl = await this.codeExecutorFactory.getExecutor(task, cloud);
        return impl;
    }

    public async executeCode(task: TaskInfo, cloud: IO2Cloud): Promise<any> {
        let code = TypescriptCodeExecutor.compiledCodes.get(task.codeLocation as string);
        if (!code) {
            const cloudInterfaceCode = await (await fetch("https://allconnectix.visualstudio.com/defaultcollection/algorithms/_apis/git/repositories/algorithms/items?api-version=1.0&scopepath=system/ip2p-cloud.ts")).text();
            const codeStr = `${cloudInterfaceCode}\n\n${task.codeLocation}`;

            code = ts.transpile(
                codeStr,
                <ts.CompilerOptions>{
                    allowJs: true,
                    strict: true,
                    newLine: ts.NewLineKind.LineFeed,
                    target: ts.ScriptTarget.ES5,
                }
            );

            console.debug(code);
            TypescriptCodeExecutor.compiledCodes.set(task.codeLocation as string, code);
        }

        const taskToExecute = Object.assign(<TaskInfo>{}, task);
        taskToExecute.codeType = CodeTypesEnum.SOURCE_COMPILED_TS;
        taskToExecute.codeLocation = code;
        const impl = await this.getCodeExecutor(taskToExecute, cloud);
        const results = await impl.executeCode(taskToExecute, cloud);
        return results;
    }

    public dispose(): Promise<void> {
        return Promise.resolve();
    }
}
