import { PeerInfo, PeerRelay } from '../impls/web-torrent-defs/bittorrent-dht/bittorrent-dht-defs';

export abstract class IChannelService {
    abstract get myVolunteerPeerId(): string;
    abstract get myVolunteerPeer(): PeerRelay;

    abstract get myEmployerPeerId(): string;
    abstract get myEmployerPeer(): PeerRelay;

    abstract bufToStr(buf: Uint8Array): string;
    abstract strToBuf(str: string): Uint8Array;
    abstract publishMe(hash: string): Promise<void>;
    abstract lookup(hash: string): Promise<PeerInfo[]>;
}