import { Observable } from 'rxjs';

export class ResolvablePromise<T> {
    private res_: any = null;
    private rej_: any = null;

    public readonly future: Promise<T>;

    public constructor() {
        this.future = new Promise<T>((res, rej) => {
            this.res_ = res;
            this.rej_ = rej;
        });
    }

    public resolve(value: T): void {
        if (this.res_) {
            this.res_(value);
            this.detachCallbacks();
        }
    }

    public reject(reason?: any): void {
        if (this.rej_) {
            this.rej_(reason);
            this.detachCallbacks();
        }
    }

    private detachCallbacks(): void {
      this.res_ = null;
      this.rej_ = null;
    }
}
