import { DebuggerConfig } from './debugger-config';

export class TimeoutsConfig {
    public static readonly debuggingTimeoutIntervalMs = 30 * 60 * 1000;//30 minutes

    public static get transitionsTimeoutMs() {
        return TimeoutsConfig.valueOrDebug(10 * 1000);
    }

    public static get messagingTimeoutMs() {
        return TimeoutsConfig.valueOrDebug(30 * 1000);
    }

    public static get waitingForJobApplicationTimeoutMs() {
        return TimeoutsConfig.valueOrDebug(30 * 1000);
    }

    public static get lookingForJobTimeoutMs() {
        return TimeoutsConfig.valueOrDebug(60 * 1000);
    }

    private static valueOrDebug(intervalMs: number): number {
        if (DebuggerConfig.isDebugging) {
            return TimeoutsConfig.debuggingTimeoutIntervalMs;
        }

        return intervalMs;
    }
}
