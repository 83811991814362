export class TimeoutError extends Error {
    public constructor(message?: string) {
        // 'Error' breaks prototype chain here
        super(message);

        // restore prototype chain   
        const actualProto = new.target.prototype;

        if (Object.setPrototypeOf) {
            Object.setPrototypeOf(this, actualProto);
        }
        else {
            const self: any = this;
            self.__proto__ = actualProto;
        }
    }
}
