import { Component, AfterViewInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import * as cocoSsd from '@tensorflow-models/coco-ssd';
import '@tensorflow/tfjs';

@Component({
    selector: 'demo-ai-video',
    templateUrl: './demo-ai-video.component.html',
    styleUrls: ['./demos-ai-video.component.scss'],
    host: { 'class': 'demo-component' },
})
export class DemoAiVideoComponent implements AfterViewInit, OnDestroy {
    @ViewChild('videoElement', { read: ElementRef, static: true }) videoElement: ElementRef;

    isStartEnabled: boolean = false;
    isStopEnabled: boolean = false;

    shouldRecord: boolean = false;
    isRecording: boolean = false;

    model: cocoSsd.ObjectDetection;
    stream: any;

    isLoading = true;

    counter: number = 0;
    objectsList: Map<string, boolean> = new Map<string, boolean>([
        ['person', false],
        ['toothbrush', false],
        ['book', false],
        ['mouse', false],
        ['apple', false],
        ['banana', false],
        ['spoon', false],
        ['knife', false],
        ['fork', false],
        ['cup', false],
        ['bottle', false],
        ['giraffe', false],
        ['dog', false],
        ['cat', false]
    ]);

    async ngAfterViewInit(): Promise<void> {
        await this.prepareMedia();
    }

    async prepareMedia(): Promise<void> {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: false,
                video: true
            });

            this.stream = stream;
            this.videoElement.nativeElement.srcObject = stream;
            this.isStartEnabled = true;

            await this.loadCocoModel();

        } catch (error) {
            console.log(error);
        }
    }

    async loadCocoModel(): Promise<void> {
        this.model = await cocoSsd.load();
        this.isLoading = false;
        await this.start();
    }

    async start() {
        this.shouldRecord = true;
        this.isStartEnabled = false;
        this.isStopEnabled = true;
        await this.detectFrame();
    }

    stop() {
        this.shouldRecord = false;
        this.isStartEnabled = true;
        this.isStopEnabled = false;
        this.stopRecording();
    }

    async detectFrame() {
        if (!this.shouldRecord) {
            this.stopRecording();
            return;
        }

        const predictions = await this.model.detect(this.videoElement.nativeElement);

        if (this.counter > 15) {
            this.objectsList.forEach((val, key) => {
                this.objectsList.set(key, false);
            });
            this.counter = 0;
        } else {
            this.counter++;
        }

        predictions.forEach((prediction) => {
            if (this.objectsList.has(prediction.class)) {
                this.objectsList.set(prediction.class, true);
            }
        });

        requestAnimationFrame(async () => {
            await this.detectFrame();
        });
    }

    async startRecording() {
        if (this.isRecording) {
            return;
        }

        this.isRecording = true;
        console.log("Start recording");
    }

    stopRecording() {
        if (!this.isRecording) {
            return;
        }

        this.isRecording = false;
        console.log("Stopped recording");
    }

    ngOnDestroy(): void {
        this.stop();
        if (this.stream != null) {
            this.stream.getTracks().forEach(function (track) {
                track.stop();
            });
        }
    }
}
