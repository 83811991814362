import { Component, OnInit, OnDestroy } from "@angular/core";
import { IDisposable } from "../p2p-cloud/misc/interfaces/idisposable";
import { ICryptography } from "../p2p-cloud/flow/interfaces/icryptography";
import { RealCryptographyService } from "../p2p-cloud/core/real-cryptography.service";
import { JobApplicationPayload } from "../p2p-cloud/flow/interfaces/task-info";
import { InternalAsymmetricCryptoApi } from "../p2p-cloud/core/internal-asymmetric-crypto-api";
import { InternalSymmetricCryptoApi } from "../p2p-cloud/core/internal-symmetric-crypto-api";
import { IO2Cloud } from "../p2p-cloud/flow/interfaces/io2-cloud";
import { UUID } from "angular2-uuid";
import { AuthenticationService } from 'src/app/services';

const isTouchDevice = "ontouchstart" in window;

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    host: { class: isTouchDevice ? "app-root is-touch-device" : "app-root" },
})
export class AppComponent implements OnInit, OnDestroy {
    title = "app";
    userId = UUID.UUID();

    private cloudShutdowner: IDisposable;

    constructor(
        private readonly cloud: IO2Cloud,
        private readonly authenticationService: AuthenticationService,
        private readonly cryptography: ICryptography) {
    }

    async ngOnInit() {
        await this.testCryptography();

        const currentUser = this.authenticationService.currentUserValue;
        if(currentUser) {
            const userHash = await this.cryptography.hashOf(currentUser.username);
            this.cloudShutdowner = await this.cloud.start([userHash]);
        } else {
            this.cloudShutdowner = await this.cloud.start([this.userId]);
        }        
    }

    private async testCryptography() {
        const srcData = "Welcome Home!";
        const srcBinaryData = new TextEncoder().encode(srcData);
        const aesKey = await InternalSymmetricCryptoApi.generateKey();
        const keys = await InternalAsymmetricCryptoApi.generateKeyPair();
        const base64Key = await InternalAsymmetricCryptoApi.exportKey(
            keys.publicKey
        );
        const importedKey = await InternalAsymmetricCryptoApi.importKey(
            base64Key
        );
        const encryptedData = await InternalAsymmetricCryptoApi.encrypt(
            importedKey,
            srcBinaryData
        );
        const cryptography1: ICryptography = new RealCryptographyService();
        const cryptography2: ICryptography = new RealCryptographyService();
        const strPublicKey1 = await cryptography1.myPublicKey();
        const strPublicKey2 = await cryptography2.myPublicKey();
        const publicKey2 = await cryptography1.importKey(strPublicKey2);
        const srcObj = <JobApplicationPayload>{
            publicKey: strPublicKey1,
            requiredCapabilities: [],
        };
        console.log(`Object to encrypt: `, srcObj);
        const encrypted = await cryptography1.encrypt(srcObj, publicKey2);
        const decrypted = await cryptography2.decrypt(encrypted);
        console.log(`Object decrypted: `, decrypted);
        const objectsAreEqual =
            JSON.stringify(srcObj) === JSON.stringify(decrypted);
        if (objectsAreEqual) console.log(`Objects are equal.`);
        else console.error(`Objects are NOT equal!`);
    }

    async ngOnDestroy() {
        await this.cloudShutdowner.dispose();
    }
}
