export class DebuggerConfig {
    private static isDebugging_: boolean = true;

    public static get isDebugging(): boolean {
        return DebuggerConfig.isDebugging_;
    }

    public static setIsDebugging(value: boolean): void {
        DebuggerConfig.isDebugging_ = value;
    }
}
