import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IO2Cloud } from '../p2p-cloud/flow/interfaces/io2-cloud';
import { RtcConnection } from '../p2p-cloud/web-rtc/RtcConnection';
import { AuthenticationService } from 'src/app/services';
import { ICryptography } from '../p2p-cloud/flow/interfaces/icryptography';

@Component({
    selector: 'demo-video-chat',
    templateUrl: './demo-video-chat.component.html',
    host: {'class': 'page-component'},
})
export class DemoVideoChatComponent implements OnInit, AfterViewInit {
    @ViewChild('videoElement', { read: ElementRef, static: false }) videoElement: ElementRef;
    @ViewChild('videoElementMe', { read: ElementRef, static: false }) videoElementMe: ElementRef;

    formGroupMain: FormGroup;
    connection: RtcConnection;
    userHash: string;

    public constructor(
        private formBuilder: FormBuilder,
        private readonly cloud: IO2Cloud,
        private readonly authenticationService: AuthenticationService,
        private readonly cryptography: ICryptography) {
    }

    ngOnInit(): void {
        this.formGroupMain = this.formBuilder.group({
            username: ['', Validators.required],
        });
    }

    async ngAfterViewInit(): Promise<void> {
        this.authenticationService.currentUser.subscribe(async (user) => {
            this.userHash = await this.cryptography.hashOf(user.username);

            this.formGroupMain.get('username').setValue(user.username);
            this.connection = new RtcConnection(this.cloud, this.userHash);
            this.connection.getVideoElements = async () => {
                return [this.videoElementMe, this.videoElement];
            };
            this.connection.answer = async () => {
                return confirm('Answer');
            };
        });
    }

    public async onSubmit() {
        const callee = await this.cryptography.hashOf(this.formGroupMain.get('username').value);

        if (await this.connection.call(this.userHash, callee) !== true) {
            alert('Rejected!');
        }
    }
}
