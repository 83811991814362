import { Component, OnInit } from '@angular/core';
import { GunDb } from '../../services/gun/gun.service';
import { DatePipe } from '@angular/common'

@Component({
    selector: 'demo-decentralized-db',
    templateUrl: './demo-decentralized-db.component.html',
    host: {'class': 'demo-component'},
})
export class DemoDecentralizedDbComponent implements OnInit {
    newTodo = '';
    todos: any;
    todosLogs: any;
    todosMap = new Map();
    todosMapLogs = new Map();

    constructor(
        private gunDb: GunDb,
        public datepipe: DatePipe
    ) { }

    ngOnInit() {
        let localDb = this.gunDb.gun.get('todos-skynetix3');
        this.todos = localDb.get('todos');
        this.todosLogs = localDb.get('logs');

        this.todos.on(data => {
            Object.keys(data).forEach(element => {
                if (element === '_') {
                    return;
                }

                if (data[element] == null) {
                    this.todosMap.delete(element);
                } else {
                    this.todosMap.set(element, data[element]);
                }
            });
        }, { change: true });

        this.todosLogs.on(data => {
            Object.keys(data).forEach(element => {
                if (element === '_') {
                    return;
                }

                this.todosMapLogs.set(element, data[element]);
            });
        }, { change: true }); 
    }

    add() {
        if (this.newTodo) {
            let date = this.datepipe.transform(new Date(), "dd.MM.yyyy H:mm:ss");

            this.todos.get(this.newGuid()).put(this.newTodo);
            this.todosLogs.get(this.newGuid()).put('Added: [' + date + ']: [' + this.newTodo + ']');
            this.newTodo = '';
        }
    }

    delete(key: string, val: string) {
        let date = this.datepipe.transform(new Date(), "dd.MM.yyyy H:mm:ss");

        this.todosLogs.get(this.newGuid()).put('Deleted: [' + date + ']: [' + val + ']');
        this.todos.get(key).put(null);
    }

    newGuid() {
        let date = this.datepipe.transform(new Date(), "dd.MM.yyyy H:mm:ss");

        return date + 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            const r = Math.random() * 16 | 0, v = c === 'x' ? r : ( r & 0x3 | 0x8 );
            return v.toString(16);
        });
    }
}
