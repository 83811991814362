import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { User } from "../models/user";
import { GunDb } from "./gun/gun.service";
import { UUID } from "angular2-uuid";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    user: any;

    constructor(private gunDb: GunDb) {
        this.user = this.gunDb.gun.user();

        this.currentUserSubject = new BehaviorSubject<User>(
            JSON.parse(localStorage.getItem("currentUser"))
        );
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue() {
        return this.currentUserSubject.value;
    }

    public async login(username, password): Promise<User> {
        const resultsFuture = new Promise<User>((resolve, reject) => {
            this.user.auth(username, password, (ack) => {
                if (ack.err) {
                    reject(new Error(ack.err));
                } else {
                    resolve(<User>{
                        username: username,
                        token: UUID.UUID(),
                        balance: 0, //TODO get from DB
                    });
                }
            });
        });

        try {
            const user = await resultsFuture;            
            localStorage.setItem("currentUser", JSON.stringify(user));
            this.currentUserSubject.next(user);
            return user;
        } catch (e) {
            throw e;
        }
    }

    public async register(username, password, password2): Promise<User> {
        if (!password || password !== password2)
            throw new Error("Passwords are not equal");
        const resultsFuture = new Promise<User>((resolve, reject) => {
            this.user.create(username, password, (ack) => {
                if (ack.err) {
                    reject(new Error(ack.err));
                } else {
                    resolve(<User>{
                        username: username,
                        token: UUID.UUID(),
                        balance: 0, //TODO get from DB
                    });
                }
            });
        });

        try {
            return await resultsFuture;
        } catch (e) {
            throw e;
        }
    }

    async update(user: User): Promise<User> {
        throw new Error("Not implemented");
    }

    logout() {
        // remove user from local storage and set current user to null
        localStorage.removeItem("currentUser");
        this.currentUserSubject.next(null);
    }
}
