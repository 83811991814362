export abstract class IHardwareInfo {
    public abstract get totalStorageSizeBytes(): number;
    public abstract get availableStorageSizeBytes(): number;
    public abstract get usedStorageSizeBytes(): number;

    public abstract get totalMemorySizeBytes(): number;
    public abstract get availableMemorySizeBytes(): number;
    public abstract get usedMemorySizeBytes(): number;

    public abstract get isGpuAvailable(): boolean;
    public abstract get numberOfCpuCores(): number;
    public abstract set numberOfCpuCores(value: number);

    public abstract get isMobile(): boolean;
}
