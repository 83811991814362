export enum EmployerStatesEnum {
    INITIAL = "emp:INITIAL",

    STARTED = "emp:STARTED",

    RECEIVED_JOB_APPLICATION = "emp:RECEIVED_JOB_APPLICATION",

    SENT_JOB_OFFER = "emp:SENT_JOB_OFFER",
    RECEIVED_SIGNED_CONTRACT = "emp:RECEIVED_SIGNED_CONTRACT",

    WAITING_FOR_RESULTS = "emp:WAITING_FOR_RESULTS",
    RECEIVED_RESULTS = "emp:RECEIVED_RESULTS",

    REQUESTED_CANCELLATION = "emp:REQUESTED_CANCELLATION",
    RECEIVED_CANCELLATION_CONFIRMATION = "emp:RECEIVED_CANCELLATION_CONFIRMATION",

    TIMEOUT = "emp:TIMEOUT",
    ERROR = "emp:ERROR",

    COMPLETED = "emp:COMPLETED",
    TERMINATED = "emp:TERMINATED"
}
