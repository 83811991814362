import { IDisposableAsync } from '../../misc/interfaces/idisposable';
import { ResolvablePromise } from '../../core/resolvable-promise';

export enum CodeTypesEnum {
    SOURCE_JS = "SOURCE_JS",
    SOURCE_JS_URL = "SOURCE_JS_URL",
    SOURCE_COMPILED_TS = "SOURCE_COMPILED_TS",
    SOURCE_TS = "SOURCE_TS",
    SOURCE_TS_URL = "SOURCE_TS_URL",
    WEB_ASSEMBLY = "WEB_ASSEMBLY",
    WEB_ASSEMBLY_URL = "WEB_ASSEMBLY_URL",
}

export class TaskInfo {
    requiredCapabilities: any;
    codeType: CodeTypesEnum;
    codeLocation: string | Function;
    actionToInvoke?: string;
    args: any[];
    imports?: any;
    timeout?: number;
    retryCount?: number;
}

export interface Paperwork {
    origins: string[];
    task: TaskInfo;
    jobAdvertizing: JobAdvertizingPayload;
    jobApplication: JobApplicationPayload;
    signedContract: SignedContractPayload;
    warRoomDisposal: IDisposableAsync;
    resultsFuture: ResolvablePromise<any>;
    results: any;
    error: any;
    executionStarted: Date;
    executionCompleted: Date;
}

export enum MessageTagsEnum {
    JOB_ADVERTIZING = "JOB_ADVERTIZING",
    JOB_APPLICATION = "JOB_APPLICATION",
    JOB_OFFER = "JOB_OFFER",
    SIGNED_CONTRACT = "SIGNED_CONTRACT",
    RESULTS_READY = "RESULTS_READY",
    ERROR = "ERROR",
    TIMEOUT = "TIMEOUT",
}

export interface Message {
    correlationId: string;
    tag: MessageTagsEnum;
    payload: JobAdvertizingPayload | JobApplicationPayload | JobOfferPayload | SignedContractPayload | ResultsPayload | ErrorPayload | EncryptedPayload;
    originNodeId: string;
}

export interface BasePayload {
    //correlationId: string;
}

export interface BaseHiringHandshakePayload extends BasePayload {
    publicKey: string;
    requiredCapabilities: any;
}

export interface JobAdvertizingPayload extends BaseHiringHandshakePayload {
}

export interface JobApplicationPayload extends BaseHiringHandshakePayload {
}

export interface JobOfferPayload extends BasePayload {
    task: TaskInfo;
}

export interface SignedContractPayload extends BasePayload {
}

export interface ResultsPayload extends BasePayload {
    results: any;
    elapsedMs: number;
}

export interface ErrorPayload extends BasePayload {
    error: any;
    elapsedMs: number;
}

export interface EncryptedPayload extends BasePayload {
    data: string;
}
