import { ICodeExecutorFactory } from '../../interfaces/icode-executor-factory';
import { ICodeExecutor } from '../../interfaces/icode-executor';
import { CodeTypesEnum, TaskInfo } from '../../interfaces/task-info';
import { IO2Cloud } from '../../interfaces/io2-cloud';
import { SourceCodeExecutor } from './javascript/source-code-executor';
import { TypescriptCodeExecutor } from './typescript/typescript-code-executor';
import { CachedWebAssemblyUrlCodeExecutor } from './webasm/cached-webassemblyurl-code-executor';
import { CompiledTypescriptCodeExecutor } from './typescript/compiled-typescript-code-executor';
import { TypescriptUrlCodeExecutor } from './typescript/typescript-url-code-executor';

type ExecutorCreatorFunc = (task: TaskInfo, cloud: IO2Cloud) => ICodeExecutor;

export class CodeExecutorFactory implements ICodeExecutorFactory {

    private readonly creators: ReadonlyMap<CodeTypesEnum, ExecutorCreatorFunc> = new Map<CodeTypesEnum, ExecutorCreatorFunc>([
        [CodeTypesEnum.SOURCE_JS, (task: TaskInfo, cloud: IO2Cloud) => new SourceCodeExecutor()],
        [CodeTypesEnum.SOURCE_TS, (task: TaskInfo, cloud: IO2Cloud) => new TypescriptCodeExecutor(this)],
        [CodeTypesEnum.SOURCE_TS_URL, (task: TaskInfo, cloud: IO2Cloud) => new TypescriptUrlCodeExecutor(this)],
        [CodeTypesEnum.SOURCE_COMPILED_TS, (task: TaskInfo, cloud: IO2Cloud) => new CompiledTypescriptCodeExecutor()],
        [CodeTypesEnum.WEB_ASSEMBLY_URL, (task: TaskInfo, cloud: IO2Cloud) => new CachedWebAssemblyUrlCodeExecutor()]
    ]);

    getExecutor(task: TaskInfo, cloud: IO2Cloud): ICodeExecutor {
        const creator = this.creators.get(task.codeType);
        if (!creator) {
            throw new Error(`No such code type is supported [yet]: ${task.codeType}`);
        }

        return creator(task, cloud);
    }

    public dispose(): Promise<void> {
        return;
    }
}
