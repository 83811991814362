//import PeerRelay from 'peer-relay';
//import DHT from 'bittorrent-dht';

export type Buffer = Uint8Array;

export interface DHTOptions {
    socket?: any;
    nodeId?: string | undefined;// 160-bit DHT node ID (Buffer or hex string, default: randomly generated)
    bootstrap?: string[];// bootstrap servers (default: router.bittorrent.com:6881, router.utorrent.com:6881, dht.transmissionbt.com:6881)
    host?: boolean;//= false;// host of local peer, if specified then announces get added to local table (String, disabled by default)
    concurrency?: number;// = 16;// k-rpc option to specify maximum concurrent UDP requests allowed (Number, 16 by default)
    hash?: Function;// = undefined;// custom hash function to use (Function, SHA1 by default),
    krpc?: any;// krpc(),// optional k-rpc instance
    timeBucketOutdated?: number;// = 900000, // check buckets every 15min
    maxAge?: number;// = Infinity  // optional setting for announced peers to time out
    isIP?: () => boolean;//function () { return true } // Prevents DNS resolves on PeerRelay IDs
    role: String; //EMPLOYER, VOLUNTEER
}

export type PutSignatureGenerator = (buff: Buffer) => Buffer;
export type PutSequenceGenerator = any;

export type PutGetCallbackResArg = {
    v: any;// the value put in
    id: Buffer;// the node that returned the content
    k: any;// the public key(only present for mutable data)
    sig: any;// the signature(only present for mutable data)
    seq: any;// the sequence(optional, only present for mutable data)
};

export type PutCallbackResArg = PutGetCallbackResArg;
export type GetCallbackResArg = PutGetCallbackResArg;

export type DHTMemento = {
    nodes: any[];
    values: any;
};

export type DHTNode = {
    host: string;
    port: number;
};

export type PutOptionsImmutable = {
    v: any//value
};

export type PutOptionsMutable = {
    v: any;//value
    k: Buffer;// ed25519 public key buffer(32 bytes)(REQUIRED)
    sign: PutSignatureGenerator;//(buf) - function to generate an ed25519 signature buffer(64 bytes) corresponding to the opts.k public key(REQUIRED)
    seq?: PutSequenceGenerator;// optional sequence(integer), must monotonically increase(REQUIRED)
    cas?: any;// optional previous sequence for compare - and - swap
    salt?: Buffer;// optional salt buffer to include(<64 bytes) when calculating the hash of the content.You can use a salt to have multiple mutable addresses for the same public key opts.k
};

export type GetHashArg = string | Buffer;

export type GetOptionsArg = {
    verify: Function;// override the default ed25519 verification function supplied during DHT instantiation.
    salt?: Buffer;// optional salt buffer(if any) that was used to calculate the hash.Must be specified if included in the hash.
    cache?: boolean;// use locally cached response value when available instead of performing a network lookup(defaults to true).    
};

export type PeerInfo = {
    host: string;
    id: Buffer;
    port: number;
};

export type LookupCallback = (error: any | null, numberOfNodesFound: number, foundPeers: any[]) => void;
export type LookupAbortFunc = () => void;
export type PutCallback = (err: any, res: PutCallbackResArg) => void;
export type GetCallback = (err: any, res: GetCallbackResArg) => void;
export type OnPeerEventCallback = (peer: PeerInfo, infoHash: string, from: any) => void;
export type OnErrorOrWaringEventCallback = (err) => void;
export type OnWarningEventCallback = OnErrorOrWaringEventCallback;
export type OnFatalErrorEventCallback = OnErrorOrWaringEventCallback;
export type OnNodeEventCallback = (node: PeerInfo) => void;
export type OnAnnounceEventCallback = (peer, infoHash) => void;

export declare interface IPRSocket {
    start(opts: PeerRelayOptions): PeerRelaySocket;
    bufToStr(buf: Uint8Array): string;
    strToBuf(str: string): Uint8Array;
}

export declare class DHT {
    new(opts?: DHTOptions): DHT;
    readonly socket: PeerRelaySocket;
    lookup(infoHash: string | Buffer, callback?: LookupCallback): LookupAbortFunc;
    listen(port?: number, address?: string, onlistening?: Function): void;
    address(): Address;
    announce(infoHash: string, port?: number, callback?: Function): void;
    toJSON(): DHTMemento;
    addNode(node: DHTNode): void;
    destroy(callback?: Function): void;
    put(opts: PutOptionsImmutable | PutOptionsMutable, callback: PutCallback): Buffer;
    get(hash: GetHashArg, opts: GetOptionsArg, callback: GetCallback): void;
    on(event: DHTEventKinds.READY, callback: Function): void;
    on(event: DHTEventKinds.LISTENING, callback: Function): void;
    on(event: DHTEventKinds.PEER, callback: OnPeerEventCallback): void;
    on(event: DHTEventKinds.NODE, callback: OnNodeEventCallback): void;
    on(event: DHTEventKinds.ANNOUNCE, callback: OnAnnounceEventCallback);
    on(event: DHTEventKinds.ERROR, callback: OnFatalErrorEventCallback): void;
    on(event: DHTEventKinds.WARNING, callback: OnWarningEventCallback): void;
}

export enum DHTEventKinds {
    READY = "ready",
    LISTENING = "listening",
    PEER = "peer",
    NODE = "node",
    ANNOUNCE = "announce",
    ERROR = "error",
    WARNING = "warning"
}

export type PeerRelayOptions = {
    port?: number;// The port for the web socket server to listen on.If not defined, then a websocket server is not started
    bootstrap: string[];// an array of web socket urls to peers already connected to the network
    wrtc?: any;// custom nodejs webrtc implementation.Check out electron - webrtc or wrtc
};

export type Address = {
    address: string;// local peer's id
    port: number;// random number or whatever port socket.bind([port]) was given
    family: string;// a string equal to 'peer-relay'
};

export type OnConnectPeerEventCallback = (data: any, from: any) => void;
export type OnPeerPeerEventCallback = (id: any) => void;

export declare class PeerRelay {
    new(options?: PeerRelayOptions): PeerRelay;
    readonly Socket: PeerRelaySocket;
    id: Buffer;//The peer's id. id is 160 bit Buffer. This id is used to identify the peer within the network.
    connect(id: Buffer | string): void;
    disconnect(id: Buffer | string): void;
    send(id: Buffer | string, data: any): void;
    destroy(cb?: Function): void;
    on(event: PeerRelayEventKinds.MESSAGE, callback: OnConnectPeerEventCallback): void;
    on(event: PeerRelayEventKinds.PEER, callback: OnPeerPeerEventCallback): void;
}

export enum PeerRelayEventKinds {
    MESSAGE = "message",
    PEER = "peer"
}

// export declare abstract class PeerRelay {
//     public static readonly Socket: PeerRelaySocket;
// }

export type OnMessagePeerRelaySocketEventCallback = (buffer: any, rinfo: Address) => void;

export declare interface PeerRelaySocket {
    new(options?: any): PeerRelaySocket;
    send(buffer, offset: number, length: number, port: number, peerRelayID, callback?: Function): void;
    address(): Address;
    close(callback: Function): void;
    bind(port?: number, callback?: Function): void;
    readonly peer: PeerRelay;
    on(event: 'message', callback: OnMessagePeerRelaySocketEventCallback): void;
    on(event: 'error', callback: OnFatalErrorEventCallback): void;
    on(event: 'close', callback: Function): void;
    on(event: 'listening', callback: Function): void;
}
