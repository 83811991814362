import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services';
import { User } from 'src/app/models/user';

@Component({
    selector: 'header',
    templateUrl: './header.component.html',
    host: { 'class': 'page-header' },
})
export class HeaderComponent implements OnInit {
    isMenuOpened: boolean = false;
    currentUser: User;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService) {
            this.authenticationService.currentUser.subscribe(
                (x) => (this.currentUser = x)
            );
         }

    ngOnInit() {

    }

    toggleMenu() {
        this.isMenuOpened = !this.isMenuOpened;
    }

    closeMenu() {
        this.isMenuOpened = false;
    }

    logout() {
        this.authenticationService.logout();
        window.location.href = '/';
    }
}
