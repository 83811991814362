export enum VolunteerStatesEnum {
    INITIAL = "vol:INITIAL",

    STARTED = "vol:STARTED",

    RECEIVED_JOB_ADVERTIZING = "vol:RECEIVED_JOB_ADVERTIZING",
    SENT_JOB_APPLICATION = "vol:SENT_JOB_APPLICATION",

    RECEIVED_JOB_OFFER = "vol:RECEIVED_JOB_OFFER",
    SENT_SIGNED_CONTRACT = "vol:SENT_SIGNED_CONTRACT",

    WORKING = "vol:WORKING",
    PRODUCED_RESULTS = "vol:PRODUCED_RESULTS",
    SENT_RESULTS = "vol:SENT_RESULTS",

    REQUESTED_CANCELLATION = "vol:REQUESTED_CANCELLATION",
    SENT_CANCELLATION_CONFIRMATION = "vol:SENT_CANCELLATION_CONFIRMATION",
    CANCELLED = "vol:CANCELLED",

    TIMEOUT = "vol:TIMEOUT",
    ERROR = "vol:ERROR",

    COMPLETED = "vol:COMPLETED",
    TERMINATED = "vol:TERMINATED"
}
