import { IHRManager } from '../../interfaces/ihr-manager';
import { ICodeExecutorFactory } from '../../interfaces/icode-executor-factory';
import { IO2Cloud } from '../../interfaces/io2-cloud';
import { CancellationToken } from '../../../core/cancellation-token';
import { VolunteerFsm } from './volunteer-fsm';
import { ICryptography } from '../../interfaces/icryptography';
import { IVolunteerFlowFsm } from '../../interfaces/iflow-fsm';
import { IChannelService } from '../../interfaces/ichannel-service';
import { IComputationTimeRepository } from '../../interfaces/Icomputation-time-repository';
import { AuthenticationService } from 'src/app/services';

export class VolunteerFsmFactory {
    public async start(
        cloud: IO2Cloud,
        hrManager: IHRManager,
        correlationId2volunteerMap: Map<string, IVolunteerFlowFsm>,
        cryptographyService: ICryptography,
        shutdownToken: CancellationToken,
        codeExecutorFactory: ICodeExecutorFactory,
        channelService: IChannelService,
        computationTimeRepository: IComputationTimeRepository,
        authenticationService: AuthenticationService
    ): Promise<IVolunteerFlowFsm> {
        const fsm = new VolunteerFsm(
            cloud,
            hrManager,
            correlationId2volunteerMap,
            cryptographyService,
            shutdownToken,
            codeExecutorFactory,
            channelService,
            computationTimeRepository,
            authenticationService
        );
        await fsm.start();
        return fsm;
    }
}
