import { Injectable } from '@angular/core';
import { IRuntime } from '../flow/interfaces/iruntime';

@Injectable()
export class RuntimeService implements IRuntime {

  private isShutdownRequested_ = false;

  public async isShutdownRequested(): Promise<boolean> { return this.isShutdownRequested_; }
  public async requestShutdown(): Promise<void> { this.isShutdownRequested_ = true; }
}
