import {Component} from '@angular/core';

@Component({
    selector: 'app-team-page',
    templateUrl: './team-page.component.html',
    host: {'class': 'page-component'},
})
export class TeamPageComponent {
    constructor() {
    }
}
