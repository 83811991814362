import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../../services';

@Component({
    selector: 'main-page',
    templateUrl: 'register-page.component.html',
    host: { 'class': 'page-component' },
})
export class RegisterPageComponent implements OnInit {
    formGroupMain: FormGroup;
    loading = false;
    returnUrl: string;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private toastrService: ToastrService,
        private authenticationService: AuthenticationService
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/demos']);
        }
    }

    ngOnInit() {
        this.formGroupMain = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
            password2: ['', Validators.required],
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/demos';
    }

    async onSubmit() {
        // stop here if form is invalid
        if (this.formGroupMain.invalid) {
            return;
        }

        this.loading = true;
        try {
            const user = await this.authenticationService.register(
                this.formGroupMain.get('username').value,
                this.formGroupMain.get('password').value,
                this.formGroupMain.get('password2').value
            );

            console.log(user);

            this.router.navigate(['/login']);
        } catch (e) {
            this.toastrService.error(e.message);
            this.loading = false;
        }
    }
}
